import React, { useEffect, useState } from "react";
import { 
  rightImage,
  leftImage,
  logoT,
} from "../../common/assets/images";
import { Link, useParams, useNavigate } from "react-router-dom";
import useShowCart from "../../components/layout/useShowCart";
import { APIS_Customers } from "../../helpers/ApiConsumo2";
import Popup from "../../components/Popup";

import {
  googleIcon,
  hidePassword,
} from "../../common/assets/images";

const ResetPassword = () => {

  const { id, token } = useParams();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  useEffect(() => {
    // Verificar si el usuario está autenticado
    const credencialesUsuario = localStorage.getItem('credencialesUsuario');
    const isAuthenticated = credencialesUsuario ? JSON.parse(credencialesUsuario).token : null;
    if (isAuthenticated) {
      navigate('/account');
    }
  }, [navigate]);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleTogglePasswordVisibility2 = () => {
    setShowPassword2((prevState) => !prevState);
  };

  const [formData, setFormData] = useState({
    new_password: "",
    confirm: "",
  });

  useShowCart();

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Actualizar el estado del formulario
    setFormData({
      ...formData,
      [name]: value,
    });
  };

     // Función para validar el formulario
     const validateForm = () => {
      for (let key in formData) {
        if (formData[key].trim() === "") {
          return false;
        }
      }
      return true;
    };

  const openPopup = (message) => {
    setPopupMessage(message);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    if (popupMessage === "Password reset successfully.") {
      navigate("/sign-in");
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setPopupMessage("Please fill in all the fields before submitting.");
      setIsPopupOpen(true);
      return;
    }else{

    // Verificar que las contraseñas coincidan
    if (formData.new_password !== formData.confirm) {
      openPopup("Passwords do not match. Please try again.");
      return;
    }

    try {
      const data = {
        uidb64: id,
        token: token,
        new_password: formData.new_password,
      };

      const response = await APIS_Customers('reset', '', data);

      // Manejo de éxito de la solicitud
      if (response && response.message === "Password has been reset successfully!") {
        openPopup("Password reset successfully.");
      } else {
        openPopup("There was an issue resetting your password. Please try again.");
      }
    } catch (error) {
      openPopup("There was an error submitting your message. Please try again.");
    }
  }
  };

  return (
    <div className="noScrollBar signup-container">
      <div className="glass-bg">
        <form onSubmit={onSubmit} className="signup-form">
          <span className="cormorant-font signup-form-title">
            Forgot password?
          </span>
          <span className="forgot-password-subhead">
            Choose a new password for your account.
          </span>
          <div className="signup-form-group">
            <label htmlFor="password" className="signup-form-label">
              Password
            </label>
            <div style={{ position: "relative" }}>
              <input
                name="new_password"
                type={showPassword ? "text" : "password"}
                className="signup-form-control"
                placeholder="Your password"
                value={formData.new_password}
                onChange={handleChange}
              />
              <img
                onClick={handleTogglePasswordVisibility}
                style={{
                  width: "30px",
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  right: "10px",
                  cursor: "pointer",
                }}
                src={hidePassword}
                alt=""
              />
            </div>
          </div>
          <div className="signup-form-group">
            <label htmlFor="confirm" className="signup-form-label">
              Confirm Password
            </label>
            <div style={{ position: "relative" }}>
              <input
                name="confirm"
                type={showPassword2 ? "text" : "password"}
                className="signup-form-control"
                placeholder="Confirm your password"
                value={formData.confirm}
                onChange={handleChange}
              />
              <img
                onClick={handleTogglePasswordVisibility2}
                style={{
                  width: "30px",
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  right: "10px",
                  cursor: "pointer",
                }}
                src={hidePassword}
                alt=""
              />
            </div>
          </div>

          <button className="signup-btn">Submit</button>
          <div className="signup-haveaccount">
            <span style={{ color: "#9F9694" }}>Remember Password?</span>
            <a
              style={{
                color: "#D1A757",
                cursor: "pointer",
                textDecoration: "none",
              }}
              href={"/sign-in"}
            >
              <span>Log In</span>
            </a>
          </div>
        </form>
      </div>
      
      {/* Popup para mostrar mensajes */}
      <Popup
        title="Alert"
        message={popupMessage}
        isOpen={isPopupOpen}
        onClose={closePopup}
      />
    </div>
  );
};

export default ResetPassword;
