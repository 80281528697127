import React, { useState, useEffect } from "react";
import {
  googleIcon,
  hidePassword,
} from "../../common/assets/images";
import useShowCart from "../../components/layout/useShowCart";
import { APIS_Customers } from "../../helpers/ApiConsumo2";
import useUserStore from "../../store/store";
import { useNavigate } from "react-router-dom";
import Popup from "../../components/Popup"; // Asegúrate de importar el popup

const SignIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Estado para controlar el popup
  const [popupMessage, setPopupMessage] = useState(""); // Mensaje del popup
  const navigate = useNavigate();
  
  // Accedemos a setUserData desde el store de Zustand
  const setUserData = useUserStore((state) => state.setUserData);
  
  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleInputChange2 = (e) => {
    setPassword(e.target.value);
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const openPopup = (message) => {
    setPopupMessage(message);
    setIsPopupOpen(true);

    // Después de 2 segundos, cerrar el popup y redirigir
    setTimeout(() => {
      setIsPopupOpen(false);
      if (message === "Login successful!") {
        navigate("/");
      }
    }, 2000);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // Validación para asegurarse de que los campos no estén vacíos
    if (!email.trim() || !password.trim()) {
      openPopup("Please fill in both email and password fields.");
      return;
    }

    // Validación del formato del email
    const isValidEmail = emailRegex.test(email);
    const data = { email, password };
  
    if (isValidEmail) {
      try {
        const response = await APIS_Customers('login', '', data);
        if (response) {
          const { customer, user_id, token } = response;
  
          // Crea un objeto con todos los datos del usuario
          const credenciales = {
            token,
            user_id,
            customer,
          };
  
          // Guarda las credenciales usando el estado de Zustand y localStorage
          setUserData(credenciales);
          openPopup("Login successful!");
        } else {
          openPopup("Incorrect credentials, please check your details.");
        }
      } catch (error) {
        openPopup('There was a problem logging in, please try again.');
      }
    } else {
      openPopup('Invalid email address.');
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  useEffect(() => {
    const backgroundImage = new Image();
    backgroundImage.src = `assets/images/signup-bg.svg`;
    backgroundImage.onload = () => {
      setIsLoaded(true);
    };
    backgroundImage.onerror = () => {
    };
  }, []);

    return (
      <div className="noScrollBar signup-container">
        <div className="glass-bg">
          <form onSubmit={onSubmit} className="signup-form">
            <span className="cormorant-font signup-form-title">
              Sign In To
              <br /> Double Down
            </span>
            <div className="signup-form-group-div">
              <div className="signup-form-group">
                <label htmlFor="exampleInputEmail1" className="signup-form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="signup-form-control"
                  aria-describedby="nameHelp"
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="signup-form-group">
                <label htmlFor="password" className="signup-form-label">
                  Password
                </label>
                <div style={{ position: "relative" }}>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="signup-form-control"
                    placeholder="Your password"
                    value={password}
                    onChange={handleInputChange2}
                  />
                  <img
                    onClick={handleTogglePasswordVisibility}
                    style={{
                      width: "30px",
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                      right: "10px",
                      cursor: "pointer",
                    }}
                    src={hidePassword}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <a className="signup-forgot-password" href={"/forgot-password"}>
              <span>Forget your password?</span>
            </a>
            <button className="signup-btn">Sign In</button>
            <span className="signup-form-divider">Or sign up using</span>
            <button className="signupGoogle-btn" style={{ display: "none" }}>
              <img src={googleIcon} alt="google" />
              Sign In with Google
            </button>
            <div className="signup-haveaccount">
              <span style={{ color: "#9F9694" }}>Have an account?</span>
              <a
                style={{
                  color: "#D1A757",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                href={"/sign-up"}
              >
                <span>Sign Up</span>
              </a>
            </div>
          </form>
        </div>

        {/* Popup para mostrar mensajes */}
        <Popup
          title="Alert"
          message={popupMessage}
          isOpen={isPopupOpen}
          onClose={() => {}}
        />
      </div>
    );
};

export default SignIn;
