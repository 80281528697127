import create from 'zustand';

const useUserStore = create((set) => {
  // Obtener datos del usuario almacenados en localStorage (si existen)
  const storedUserData = localStorage.getItem('credencialesUsuario');
  const initialUserData = storedUserData ? JSON.parse(storedUserData) : null;

  return {
    userData: initialUserData,
    setUserData: (data) => {
      // Guardar los datos del usuario en el estado y en localStorage
      set({ userData: data });
      localStorage.setItem('credencialesUsuario', JSON.stringify(data));
    },
    clearUserData: () => {
      // Limpiar los datos del usuario en el estado y en localStorage
      set({ userData: null });
      localStorage.removeItem('credencialesUsuario');
    },
  };
});

export default useUserStore;
