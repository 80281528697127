import React, { useEffect, useState } from "react";
import {
  sendIcon,
  rightContactbg,
  leftContactbg1,
  leftContactbg2,
  hidePassword
} from "../../common/assets/images";
import useUserStore from "../../store/store";
import { useParams, useNavigate } from "react-router-dom"; // Añadir useNavigate para redirección
import { APIS_Customers } from "../../helpers/ApiConsumo2";
import Popup from "../../components/Popup"; // Asegúrate de importar el popup

const Request2 = () => {
  const { id } = useParams();
  const navigate = useNavigate(); // Hook para la redirección

  // Obtener los datos del usuario del estado de Zustand
  const [colocarUsuario, setColocarUsuario] = useState([]);
  const [Casino, setCasino] = useState([]);
  const [resok, setResok] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Estado para controlar el popup
  const [popupMessage, setPopupMessage] = useState(""); // Mensaje del popup

  const getUsers = async () => {
    const usuarios = await APIS_Customers('getAll');
    setResok(resok + 1);
    setColocarUsuario(usuarios);
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    setCasino(colocarUsuario);
  }, [resok]);

  const userData = useUserStore((state) => state.userData);

  const [formData, setFormData] = useState({
    Firstname: colocarUsuario?.first_name,
    Lastname: colocarUsuario?.last_name,
    Email: colocarUsuario?.email,
    Phone: colocarUsuario?.phone,
    address: colocarUsuario?.address,
    Password: "",
  });


  // Función para manejar los cambios en los campos del formulario
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Actualizar el estado del formulario
    setFormData({
      ...formData,
      [name]: value,
    });
  };

// Función para validar que al menos un campo no esté vacío y que no sea undefined
const validateNotAllEmpty = () => {
  for (let key in formData) {
    if (formData[key] !== undefined && formData[key].trim() !== "") {
      return true; // Si al menos un campo tiene un valor válido, la validación pasa
    }
  }
  return false; // Si todos los campos son vacíos o undefined, la validación falla
};

  // Función para validar el campo de teléfono
  const validatePhone = () => {
    const phone = formData.Phone.replace("+1", ""); // Remover el código de país para la validación
    return /^\d{10}$/.test(phone); // Verificar que contiene solo 10 dígitos
  };

  const openPopup = (message) => {
    setPopupMessage(message);
    setIsPopupOpen(true);

    // Después de 2 segundos, cerrar el popup y redirigir
    setTimeout(() => {
      setIsPopupOpen(false);
      if (message === "Form successfully submitted!") {
        navigate("/account");
      }
    }, 2000);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
  
    // Validar que al menos un campo tenga valor
    if (!validateNotAllEmpty()) {
      setPopupMessage("Please fill in at least one field before submitting.");
      setIsPopupOpen(true);
      return;
    }
  
    // Validar el campo de teléfono
    if (!validatePhone()) {
      setPopupMessage("Please enter a valid U.S. phone number with 10 digits.");
      setIsPopupOpen(true);
      return;
    }
  
    try {
      // Preparar los datos para la solicitud a la API
      const data = {};
  
      for (const key in formData) {
        if (formData[key] !== "" && formData[key] !== undefined) {
          if (key === "Phone") {
            data[key.toLowerCase()] = `+1${formData[key].replace("+1", "")}`; // Asegurar el prefijo +1
          } else {
            data[key.toLowerCase()] = formData[key]; // Convertimos las claves a minúsculas si es necesario
          }
        }
      }
  
  
      // Enviar la solicitud a la API
      const response = await APIS_Customers("update", "", data);

      // Verificar si la respuesta contiene errores
      if (response && response.success === false && response.errors) {
        const errorMessages = response.errors.map((error) => error.message).join(", ");
        openPopup(`Error: ${errorMessages}`);
        return;
      }
  
      // Mostrar mensaje de éxito si no hay errores
      openPopup("Form successfully submitted!");
  
      // Limpiar el formulario
      setFormData({
        Firstname: "",
        Lastname: "",
        Email: "",
        address: "",
        Phone: "",
        Password: "",
      });
    } catch (error) {
      openPopup("There was an error submitting your message. Please try again.");
    }
  };
  
  
  
  useEffect(() => {
    // Verificar si el usuario está autenticado
    const credencialesUsuario = localStorage.getItem('credencialesUsuario'); // Obtener el valor de localStorage
    const isAuthenticated = credencialesUsuario ? JSON.parse(credencialesUsuario).token : null; // Parsear y obtener el token

    if (!isAuthenticated) {
      // Si no está autenticado, redirigir al login
      navigate('/sign-in');
    }
  }, [navigate]);

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <div className="contact-main-container">
      <img
        className="leftContactbg1 contact-floating-img1"
        src={leftContactbg1}
        alt=""
      />
      <img
        className="leftContactbg2 contact-rotation-img"
        src={leftContactbg2}
        alt=""
      />
      <img
        className="rightContactbg1 contact-floating-img2"
        src={rightContactbg}
        alt=""
      />
      <div className="contact-container">
        <span className="contact-header cormorant-font gradient-text">
          Edit User
        </span>

        <form className="contact-form" onSubmit={onSubmit}>
          <div className="contact-form-group">
            <label htmlFor="Firstname" className="contact-form-label">
              First Name
            </label>
            <input
              type="text"
              name="Firstname"
              className="contact-form-control gordita-font"
              id="Firstname"
              placeholder="First Name"
              value={formData.Firstname}
              onChange={handleChange}
            />
          </div>
          <div className="contact-form-group">
            <label htmlFor="Lastname" className="contact-form-label">
              Last Name
            </label>
            <input
              type="text"
              name="Lastname"
              className="contact-form-control gordita-font"
              id="Lastname"
              placeholder="Last Name"
              value={formData.Lastname}
              onChange={handleChange}
            />
          </div>
          <div className="contact-form-group">
            <label htmlFor="Email" className="contact-form-label">
              Email
            </label>
            <input
              type="email"
              name="Email"
              className="contact-form-control gordita-font"
              id="Email"
              placeholder="Email"
              value={formData.Email}
              onChange={handleChange}
            />
          </div>
          <div className="contact-form-group">
            <label htmlFor="address" className="contact-form-label">
              Address
            </label>
            <input
              type="text"
              name="address"
              className="contact-form-control gordita-font"
              id="address"
              placeholder="Address"
              value={formData.address}
              onChange={handleChange}
            />
          </div>
          <div className="contact-form-group">
            <label htmlFor="Phone" className="contact-form-label">
              Phone
            </label>
            <input
              type="text"
              name="Phone"
              className="contact-form-control gordita-font"
              id="Phone"
              placeholder="Phone Number"
              value={formData.Phone}
              onChange={handleChange}
            />
          </div>
          <div className="contact-form-group">
            <label htmlFor="Password" className="contact-form-label">
              Password
            </label>
            <div style={{ position: "relative" }}>
              <input
                type={showPassword ? "text" : "password"}
                name="Password"
                className="contact-form-control gordita-font"
                placeholder="Your password"
                value={formData.Password}
                onChange={handleChange}
              />
              <img
                onClick={handleTogglePasswordVisibility}
                style={{
                  width: "30px",
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  right: "10px",
                  cursor: "pointer",
                }}
                src={hidePassword}
                alt=""
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              width: "100%",
            }}
          >
            <button type="submit" className="contact-btn">
              EDIT USER
              <img src={sendIcon} alt="sendIcon" />
            </button>
          </div>
        </form>
      </div>
      
      {/* Popup para mostrar mensajes */}
      <Popup
        title="Alert"
        message={popupMessage}
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
      />
    </div>
  );
};

export default Request2;
