import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Asegúrate de tener react-router-dom instalado para usar Link
import { APIS_Customers } from '../../helpers/ApiConsumo2';
import moment from 'moment';

const DashboardTable = ({colocarUsuario}) => {

    return (
        <div className="account-right-content">
            <table
                className="account-table"
                style={{ borderColor: "red", width: "100%", height: "100%" }}
            >
                <thead className="cormorant-font account-table-head">
                    <tr>
                        
                    <th>Game</th>
                        <th>Booking start</th>
                        <th style={{ width: "45%" }}>Booking end</th>
                        <th>Status</th>
                        
                    </tr>
                </thead>
                <tbody className="gordita-font account-table-body">
          {colocarUsuario?.bookings?.length > 0 ? (
            colocarUsuario?.bookings?.map((user, index) => (
              <tr key={index}>
                
                <td>{user.game.name}</td>
                <td>{moment(user.booking_start).format('YYYY-MM-DD')}</td>
                <td>{moment(user.booking_end).format('YYYY-MM-DD')}</td>
                <td>{user.status}</td>
                
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" style={{ textAlign: "center" }}>
                No orders found
              </td>
            </tr>
          )}
        </tbody>
            </table>
        </div>
    );
};

const DashboardContent = {
    title: "Dashboard",
    content: <DashboardTable />,
};

export default DashboardTable;
export { DashboardContent };
