import axios from "axios";
import {enviromentlocal } from "../enviroment/enviroment";
import { deleteData, getData, getData2, postData, postData2, postData4, updateData } from "./apiRequest";


export const APIS_GamesBooks = async (method,id,body) => {
    try {
        let response; // Variable para almacenar la respuesta de axios
        let url=enviromentlocal
      switch (method) {
        case 'getAll':
          response = await getData(url+'games/');

          break;
        case 'get':
          response = await getData2(url +'games/'+ id);
          break;
          case 'getPackage':
          response = await getData2(url +'games/?type=packs');
          break;
        case 'add':
          response = await postData4(url +'bookings/', body);
          break;
        default:
          return null;
      }
  
      return response; // Retorna los datos obtenidos de la API
    } catch (error) {
      return null; // Retorna null o maneja el error como prefieras
    }
  };