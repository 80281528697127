import React, { useState, useEffect } from 'react';
import Account1 from './Account1';
import Account2 from './Account2';
import Dashboard from './Dashboard';
import Dashboard2 from './Dashboard2';
import { useNavigate } from 'react-router-dom'; // Necesitas usar `react-router-dom` para la navegación
import { APIS_Customers } from '../../helpers/ApiConsumo2'; // Importar la API

const Sidebar = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isDesktop, setIsDesktop] = useState(window.innerHeight > 767);
  const [colocarUsuario, setColocarUsuario] = useState([]);
  const navigate = useNavigate(); // Hook para navegación

  // Obtener los usuarios de la API
  const getUsers = async () => {
    try {
      const usuarios = await APIS_Customers('getAll');
      setColocarUsuario(usuarios);
    } catch (error) {
    }
  };

  useEffect(() => {
    getUsers();
  },[])

  useEffect(() => {

    // Verificar si el usuario está autenticado
    const credencialesUsuario = localStorage.getItem('credencialesUsuario'); // Obtener el valor de localStorage
    const isAuthenticated = credencialesUsuario ? JSON.parse(credencialesUsuario).token : null; // Parsear y obtener el token

    if (!isAuthenticated) {
      // Si no está autenticado, redirigir al login
      navigate('/sign-in');
    }

    // Manejar el cambio de tamaño de la ventana
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 767);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [navigate]);

  // Definir los menús para Desktop y Mobile
  const accountMenu = [
    { title: "Dashboard", component: <Dashboard colocarUsuario={colocarUsuario} /> },
    { title: "Account 1", component: <Account1 colocarUsuario={colocarUsuario} /> },
  ];

  const accountMenuMobile = [
    { title: "Account 2", component: <Account2 colocarUsuario={colocarUsuario} /> },
    { title: "Dashboard 2", component: <Dashboard2 colocarUsuario={colocarUsuario} /> },
  ];

  const currentMenu = isDesktop ? accountMenu : accountMenuMobile;

  const handleCurrentStep = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="account-container">
      <ul className="accout-sidebar">
        {currentMenu?.map((item, index) => (
          <div
            className={activeIndex === index ? "accout-sidebar-active" : ""}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            key={index}
            onClick={() => handleCurrentStep(index)}
          >
            <li>
              {item.img && (
                <img
                  src={item.img}
                  alt=""
                  className={`${activeIndex === index ? "active-svg" : ""}`}
                />
              )}
              {item.title}
            </li>
          </div>
        ))}
      </ul>
      <div className="account-content-row">
        {currentMenu[activeIndex]?.component}
      </div>
    </div>
  );
};

export default Sidebar;
