import React, { useEffect, useState } from "react";
import {
  backArrow,
  deleteIcon,
  cartBackArrow,
  hamburger,
} from "../../common/assets/images";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeFromCart } from "../../state/actions";
import NavBar from "../../components/home/NavBar";
import useShowCart from "../../components/layout/useShowCart";


const Cart = () => {
  useShowCart();
  const dispatch = useDispatch();
  const [menu, setMenue] = useState(true);
  const cartItems = useSelector((state) => state.cartItems);
  const navigate = useNavigate();
  const handleCheckout = () => {
    navigate("/cart/checkout");
  };
  const removeItem = (index) => {
    dispatch(removeFromCart(index)); // Dispatch action to remove item from cart
    
  };

  const handleGoBack = () => {
    navigate(-1);
  };

useEffect(()=>{

})

  return (
    <div className="black-bg-padding">
      <div className="main-cart-container">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <a 
            onClick={handleGoBack}
          >
          <img
            className="main-cart-mobile-show cart-opener" 
            src={cartBackArrow}
            style={{ height: "18px", width: "18px" }}
            alt=""
          />
          </a>
          <span className="main-cart-heading cormorant-font gradient-text">
            Cart Order
          </span>
          <img
            className="main-cart-mobile-show menu-opener"
            src={hamburger}
            style={{ height: "14.03px", width: "44px" }}
            alt=""
          />
        </div>
        <div className="noScrollBar main-cart-container-inner">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "30px",
            }}
          >
            <span className="main-cart-back cormorant-font">
              <Link to={"/products"}> 
                <img src={backArrow} alt="" /> 
              </Link> 
              Back to Shop
            </span>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  gap: "30px",
                }}
              >
                <div className="cormorant-font main-cart-top-heading">
                  <span
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                    className="main-cart-table-heading"
                  >
                    Game
                  </span>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <span className="main-cart-table-heading">Price/h</span>
                    <span className="main-cart-table-heading">Total</span>
                    <span style={{ width: "40px" }}> </span>
                  </div>
                </div>
                <hr className="main-cart-t-hr" />
                {cartItems?.map((items, index) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    className="cormorant-font"
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        color: "#D1A757",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            flexDirection: "row",
                            gap: "10px",
                          }}
                        >
                          <div className="main-cart-img-div">
                            <img
                              className="main-cart-img"
                              src={items.img}
                              alt=""
                            />
                          </div>
                          <div className="main-cart-details-div">
                            <span className="main-cart-title">
                              {items.name}
                            </span>
                            <span
                              className="main-cart-category"
                              style={{ color: "#919385" }}
                            >
                              {items.name}
                            </span>
                            <div
                              className="main-cart-mobile-show"
                              style={{ display: "flex", gap: "50px" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                                className="main-cart-mobile-show"
                              >
                                <span className="main-cart-table-heading main-cart-mobile-show">
                                  Price/h
                                </span>
                                <span
                                  className="gordita-font main-cart-pt main-cart-mobile-show"
                                  style={{ color: "#FFF" }}
                                >
                                  ${items.name}
                                </span>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span className="main-cart-table-heading main-cart-mobile-show">
                                  Total
                                </span>
                                <span
                                  className="gordita-font main-cart-pt main-cart-mobile-show"
                                  style={{ color: "#FFF" }}
                                >
                                  ${items.price * items.count}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="main-cart-mobile-hide main-cart-price-div">
                        <span
                          className="gordita-font main-cart-pt main-cart-mobile-hide"
                          style={{ color: "#FFF" }}
                        >
                          ${items.price}
                        </span>
                        <span
                          className="gordita-font main-cart-pt main-cart-mobile-hide"
                          style={{ color: "#FFF" }}
                        >
                          ${items.price * items.count}
                        </span>
                        <div className="main-cart-mobile-hide main-cart-delDiv">
                          <img
                            className="main-cart-delIcon main-cart-mobile-hide"
                            src={deleteIcon}
                            alt=""
                            onClick={() => removeItem(index)}
                          />
                        </div>
                      </div>
                      <div className="main-cart-mobile-show main-cart-delDiv">
                        <img
                          className="main-cart-mobile-show"
                          src={deleteIcon}
                          alt=""
                          onClick={() => removeItem(index)}
                        />{" "}
                      </div>
                    </div>
                    <hr className="main-cart-b-hr" />
                  </div>
                ))}
              </div>
              <div className="main-cart-mobile-hide main-cart-total-container">
                <div className="main-cart-total main-cart-mobile-hide">
                  <div className="main-cart-total-div  main-cart-mobile-hide">
                    <span className="cormorant-font main-cart-total-heading main-cart-mobile-hide">
                      Total
                    </span>
                    <div className="gordita-font main-cart-total-subdiv main-cart-mobile-hide">
                      <span>Taxes</span>
                      <span style={{ color: "#fff" }}>$11</span>
                    </div>
                    <div className="gordita-font main-cart-total-subdiv main-cart-mobile-hide">
                      <span>Total</span>
                      <span style={{ color: "#fff" }}>$281</span>
                    </div>
                  </div>
                  <button
                    onClick={handleCheckout}
                    className="main-cart-checkout-btn main-cart-mobile-hide"
                  >
                    Checkout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-cart-fixed-bottom main-cart-mobile-show">
        <div className="main-cart-fixed-bottom-inner">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <span
              className="gordita-font"
              style={{ color: "#9F9694", fontSize: "20px" }}
            >
              Taxes
            </span>
            <span
              className="gordita-font"
              style={{ color: "#FFFF", fontSize: "20px" }}
            >
              {/* ${calculateSubtotal()} */}$11
            </span>
          </div>
          <hr style={{ border: "1px solid #535549", width: "100%" }} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <span
              className="gordita-font"
              style={{ color: "#9F9694", fontSize: "20px", fontWeight: 700 }}
            >
              Total
            </span>
            <span
              className="gordita-font"
              style={{ color: "#FFFF", fontSize: "20px", fontWeight: 700 }}
            >
              {/* ${calculateSubtotal()} */}$281
            </span>
          </div>
          <button
            className="main-cart-fixed-bottom-inner-btn"
            onClick={handleCheckout}
          >
            Checkout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cart;
