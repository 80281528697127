import { useRef } from 'react';
import { gsap } from 'gsap';
import {useGSAP} from '@gsap/react';
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';


export default function useMenu() {
    const showMenu = useRef(null);
    const { pathname } = useLocation();
    
    useGSAP(() => {
      showMenu.current = gsap.timeline({
        paused: true,
      })
      .to('.menu', {
        yPercent: () => 100,
      })

      const openers = gsap.utils.toArray('.menu-opener');
      openers.forEach(opener => {
        opener.addEventListener('click', openMenu)
      })

    }, [pathname]);
  
    const openMenu = () => {
      showMenu.current.tweenTo(showMenu.current.totalDuration());
    }
    const closeMenu = () => {
      showMenu.current.tweenTo(0)
    }

  return { openMenu, closeMenu };
}
