import React, { useState, useEffect } from "react";
import { rightImage, leftImage, logoT } from "../../common/assets/images";
import { useNavigate } from "react-router-dom";
import useShowCart from "../../components/layout/useShowCart";
import { APIS_Customers } from "../../helpers/ApiConsumo2";
import Popup from "../../components/Popup"; // Asegúrate de importar el popup

const ForgotPassword = () => {
  useShowCart();
  const navigate = useNavigate();
  const [requestSent, setRequestSent] = useState(false);
  const [email, setEmail] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Estado para controlar el popup
  const [popupMessage, setPopupMessage] = useState(""); // Mensaje del popup

  useEffect(() => {
    // Verificar si el usuario está autenticado
    const credencialesUsuario = localStorage.getItem('credencialesUsuario'); // Obtener el valor del localStorage
    const isAuthenticated = credencialesUsuario ? JSON.parse(credencialesUsuario).token : null; // Parsear y obtener el token
    if (isAuthenticated) {
      navigate('/account'); // Redirigir a /account si el usuario está autenticado
    }
  }, [navigate]);

  const handleInputChange = (e) => {
    setEmail(e.target.value); // Actualiza el estado con el valor del input
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const openPopup = (message) => {
    setPopupMessage(message);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const toggleRequestState = async () => {

    // Verificar si el email tiene un formato válido
    const isValidEmail = emailRegex.test(email);
    const data = {
      email: email,
    };

    const validateForm = () => {

        if (email === "") {
          return false;
        }
      
      return true;
    };
  
    if (!validateForm()) {
      setPopupMessage("Please fill in all the fields before submitting.");
      setIsPopupOpen(true);
      return;
    }else{
    if (isValidEmail) {
      try {
        // Hacer la solicitud
        const usuarios = await APIS_Customers('forgot', '', data);
        // Verificar si hay errores en la respuesta
        if (usuarios && usuarios.errors) {
          // Buscar si hay un error relacionado con el correo
          const emailError = usuarios.errors.find(
            (error) => error.field === "email"
          );

          if (emailError) {
            // Mostrar el mensaje de error al usuario
            openPopup(emailError.message);
            return;
          }
        }


        // Si no hay errores, cambiar el estado del request
        setRequestSent(!requestSent);
        openPopup("Email sent!");
      } catch (error) {
        // Verificar si el error tiene una respuesta de la API y mostrar en consola
        if (error.response && error.response.data && error.response.data.errors) {
          const apiErrors = error.response.data.errors;
          const emailError = apiErrors.find((err) => err.field === "email");

          if (emailError) {
            // Mostrar el mensaje específico del error si se encuentra
            openPopup(emailError.message);
          } else {
            // Si no es un error específico de email, mostrar el primer mensaje de error
            openPopup(apiErrors[0].message);
          }
        } else {
          openPopup("An unexpected error occurred. Please try again.");
        }
      }
    } else {
      openPopup("Invalid email address.");
    }
  }
  };

  // Enviar formulario sin realizar recarga de página
  const onSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="noScrollBar signup-container">
      <div className="glass-bg">
        <form onSubmit={onSubmit} className="signup-form">
          <span className="cormorant-font signup-form-title">
            {requestSent ? "Request Sent." : "Forgot password?"}
          </span>
          <span className="forgot-password-subhead">
            {requestSent
              ? "If that email address is in our system, you will receive an email with instructions about how to reset your password in a few minutes."
              : "Enter your registered email below to receive password reset instructions."}
          </span>
          {!requestSent && (
            <div className="signup-form-group">
              <label htmlFor="exampleInputEmail1" className="signup-form-label">
                Email
              </label>
              <input
                type="email"
                className="signup-form-control"
                aria-describedby="nameHelp"
                placeholder="Enter your email"
                value={email} // Vincula el valor del input al estado
                onChange={handleInputChange} // Actualiza el estado al cambiar el input
              />
            </div>
          )}
          {!requestSent && (
            <button onClick={toggleRequestState} className="signup-btn">
              Submit
            </button>
          )}
          {!requestSent && (
            <div className="signup-haveaccount">
              <span style={{ color: "#9F9694" }}>Remember password?</span>
              <a
                style={{
                  color: "#D1A757",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                href={"/sign-in"}
              >
                <span>Log in</span>
              </a>
            </div>
          )}
        </form>
      </div>
      
      {/* Popup para mostrar mensajes */}
      <Popup
        title="Alert"
        message={popupMessage}
        isOpen={isPopupOpen}
        onClose={closePopup}
      />
    </div>
  );
};

export default ForgotPassword;
