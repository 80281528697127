import React, { useEffect, useState, useRef } from "react";
import {
  xIcon,
  fbIcon,
  igIcon,
  cloverShape,
  close,
} from "../../common/assets/images";
// import { Link } from "react-router-dom";
import { 
  leftImage,
  rightImage,
  logo,
  logoT,
  pl1,
  pl2,
  pl3,
  pr1,
  pr2,
  pr3,
  welcomeTextImage,
  shadeShape,
  heartShape,
  scroll,
  hamburger,
  slideBase,
  rouletteIcon,
  heartsButton,
  shoppingCart,
  leftArrow,
  rightArrow} from "../../common/assets/images";
  import useMenu from '../layout/useMenu';
import useUserStore from "../../store/store";
import { useNavigate } from "react-router-dom";

const NavBar = ({ cartItems }) => {
  const { closeMenu, openMenu } = useMenu();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 968);
  const leftImageRef = useRef(null);
  const rightImageRef = useRef(null);
  const logoImageRef = useRef(null);
  const logoImageRef2 = useRef(null);
  const pl1Ref = useRef(null);
  const pl2Ref = useRef(null);
  const pl3Ref = useRef(null);
  const pr1Ref = useRef(null);
  const pr2Ref = useRef(null);
  const pr3Ref = useRef(null);
  const rightImageRef2 = useRef(null);
  const leftImageRef2 = useRef(null);
  const welcomeTextRef = useRef(null);
  const bgRef = useRef(null);
  const videoBgRef = useRef(null);
  const bg2Ref = useRef(null);
  const bg3Ref = useRef(null);
  const bg4Ref = useRef(null);
  const shadeShapeRef = useRef(null);
  const heartShapeRef = useRef(null);
  const scrollRef = useRef(null);
  const slideBaseRef = useRef(null);
  const slide1Ref = useRef(null);
  const hamburgerRef = useRef(null);
  const shoppingCartRef = useRef(null);
  const signInButtonRef = useRef(null);
  const navigate= useNavigate()

  // Obtener los datos del usuario del estado de Zustand
  const userData = useUserStore((state) => state.userData);

  useEffect(()=>{
    console.log(userData)
  },[])

    // Obtener la función clearUserData del store de Zustand
    const clearUserData = useUserStore((state) => state.clearUserData);

    // Función para manejar el cierre de sesión
    const handleLogout = () => {
      // Limpiar la información del usuario del estado y localStorage
      clearUserData();
      navigate('/');
      console.log('Usuario ha cerrado sesión');
    };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 968);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <>
      <div
      className="nav-item-container">
        <a href='/' className='left-nav-mobile'>
          <img
            ref={leftImageRef2}
            src={leftImage}
            alt="Animated"
            className="shade-logo-img"
          />
          <img
            ref={rightImageRef2}
            src={rightImage}
            alt="Animated"
            className="heart-logo-img"
          />
          <img ref={logoImageRef2} src={logoT} alt="logo" className="lp-logo" />
      </a>
      <div className='right-nav-mobile'>
      {!userData ? (
            <a href={"/sign-in"} className="nav-sign-up cormorant-font">
              SIGN IN
            </a>
            
          ) : (
            <a href={"/Account"} className="nav-sign-up cormorant-font">
              WELCOME, {userData?.customer?.first_name?.toUpperCase()}
            </a>
          )}
           {!userData ? (
            <a href={"/sign-up"} className="nav-sign-up cormorant-font">
              SIGN UP
            </a>
            
          ) : null}
          <button className="hamburger" 
              onClick={openMenu}
              >
            <img
              ref={hamburgerRef}
              src={hamburger}
              alt="logo"
            />
          </button>
      
      </div>
    </div>
    
    <div
      className='menu'
      style={{
        position: "fixed",
        top: 0,
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        backgroundColor: "#000",
        transform: 'translateY(-100%)',
        zIndex: 40,
        padding: 'min(50px, 5vw)',
        boxSizing: 'border-box',
      }}
    >
      <div 
        className="nav-close-div"
        onClick={closeMenu}
      >
        <img
          src={close}
          alt="close" 
          className="nav-close"
        />
      </div>
      <img
        src={cloverShape}
        alt="cloverShape"
        style={{
          position: "absolute",
          bottom: "0",
          right: "0",
          width: "50%",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          // padding: "50px",
          height: "100%",
        }}
      >
        <ul
          style={{
            background:
              "linear-gradient(to right, #EFCE76 0%, #835A27 38%, #C09852 73%, #F4E285 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            display: "flex",
            flexDirection: "column",
            alignItems: "self-start",
            justifyContent: "flex-start",
            fontSize: "40px",
            listStyle: "none",
            gap: "30px",
            marginLeft: 0,
            paddingLeft: 0,
          }}
          className="cormorant-font"
        >
          <a style={{ cursor: "pointer" }} href={"/quote"}>
            <li className="gradient-text nav-link">GET A QUOTE</li>
          </a>
          <a style={{ cursor: "pointer" }} href={"/products"}>
            <li className="gradient-text nav-link" style={{ cursor: "pointer" }}>
              GAMING TABLES
            </li>
          </a>
         
          {!userData && isMobile  ?  (
            <a style={{ cursor: "pointer" }} href={"/sign-up"}>
              <li className="gradient-text nav-link" style={{ cursor: "pointer" }}>
                SIGN UP
              </li>
            </a>
            
          ) : userData  ? (<a style={{ cursor: "pointer" }} href={"/account"}>
          <li className="gradient-text nav-link" style={{ cursor: "pointer" }}>
            MY ACCOUNT
          </li>
        </a>) : null}
        {!userData  ?  (
            <a style={{ cursor: "pointer" }} href={"/sign-in"}>
              <li className="gradient-text nav-link" style={{ cursor: "pointer" }}>
                SIGN IN
              </li>
            </a>

            
            
          ) : null}
           {!userData  ?  (
            <a style={{ cursor: "pointer" }} href={"/sign-up"}>
              <li className="gradient-text nav-link" style={{ cursor: "pointer" }}>
                SIGN UP
              </li>
            </a>

            
            
          ) : null}
        {userData ?  (
          <a onClick={handleLogout} style={{ cursor: "pointer" }} >
            <li className="gradient-text nav-link" style={{ cursor: "pointer" }}>
              SIGN OUT
            </li>
          </a>) : null}
        </ul>
        <div style={{ display: "flex", flexDirection: "row", gap: "30px" }}>
        <a href="https://www.facebook.com/profile.php?id=100066594605159" target="_noblank">
          <img
            src={fbIcon}
            alt="fbIcon"
            className="nav-social" 
          />
          </a>
          <a href="https://www.instagram.com/doubledowncasinoevents/" target="_noblank">
          <img
            src={igIcon}
            alt="igIcon"
            className="nav-social" 
          />
          </a>
        </div>
      </div>
    </div>
    </>

  );
};

export default NavBar;
