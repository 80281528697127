import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Asegúrate de tener react-router-dom instalado para usar Link
import { APIS_Customers } from '../../helpers/ApiConsumo2';
import moment from 'moment';

const Account_Dashboard2 = ({colocarUsuario}) => {

  

    return (
        <div className="account-right-content">
            <div
                className="account-table"
                
            >
                
                <ul className="gordita-font account-table-body">

                <li><strong >First name:</strong> {colocarUsuario?.first_name}</li>
                <li><strong>Last name:</strong> {colocarUsuario?.last_name}</li>
                
                <li><strong>Address:</strong> {colocarUsuario?.address || 'N/A'}</li>
                <li><strong>Email:</strong> {colocarUsuario?.email}</li>
                <li><strong>Phone:</strong> {colocarUsuario?.phone || 'N/A'}</li>
                <li><Link to={`/edit-user`} className='editar-usuario'>Edit</Link></li>
             
            
        
        </ul>
            </div>
            
        </div>




    );

    
};

const DashboardContent = {
    title: "Account2",
    content: <Account_Dashboard2 />,
};

export default Account_Dashboard2;
export { DashboardContent };
