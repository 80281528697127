import { useRef } from 'react';
import { gsap } from 'gsap';
import {useGSAP} from '@gsap/react';
import { useSelector } from "react-redux";


export default function useLayout() {
    const cartItems = useSelector((state) => state.cartItems);

  return { cartItems };
}
