import React, { useState, useEffect } from "react";
import { 
  googleIcon,
  hidePassword,
} from "../../common/assets/images";
import { Link, useNavigate } from "react-router-dom";
import { APIS_Customers } from "../../helpers/ApiConsumo2";
import Spinner from "../../components/spinner";
import Popup from "../../components/Popup"; // Asegúrate de importar el Popup

const SignUp = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [loading, setLoading] = useState(false); // Estado para el spinner
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Estado para controlar el popup
  const [popupMessage, setPopupMessage] = useState(""); // Mensaje del popup

  const navigate = useNavigate();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    const backgroundImage = new Image();
    backgroundImage.src = `/assets/images/signup-bg.svg`;
    backgroundImage.onload = () => {
      setIsLoaded(true);
    };
    backgroundImage.onerror = () => {
    };
  }, []);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string?.slice(1);
  };

  const openPopup = (message) => {
    setPopupMessage(message);
    setIsPopupOpen(true);

    // Cerrar el popup automáticamente después de 2 segundos
    setTimeout(() => {
      setIsPopupOpen(false);
      if (message === "User registered successfully!") {
        navigate("/sign-in");
      }
    }, 2000);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // Validación para asegurarse de que los campos no estén vacíos
    if (!firstName.trim() || !lastName.trim() || !email.trim() || !password.trim()) {
      openPopup("Please fill in all fields before submitting.");
      return;
    }

    const userData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
    };

    const isValidEmail = emailRegex.test(userData.email);

    if (isValidEmail) {
      setLoading(true); // Mostrar el spinner
      try {
        const usuarios = await APIS_Customers('register', '', userData);
        if (usuarios && 'success' in usuarios && usuarios.success !== true) {
          // Capitaliza la primera letra de usuarios.errors[0].field
          const fieldWithCapitalizedFirstLetter = capitalizeFirstLetter(usuarios?.errors[0]?.field);
          openPopup(`${fieldWithCapitalizedFirstLetter}: ` + `${usuarios.errors[0].message}`);
        } else {
          openPopup("User registered successfully!");
        }
      } catch (error) {

        // Manejar el mensaje de error específico de la API
        if (error.response && error.response.data && error.response.data.message) {
          // Mostrar el mensaje de error que devuelve la API
          openPopup(error.response.data.message);
        } else {
          // Si no hay un mensaje específico, mostrar un mensaje genérico
          openPopup("Error creating user, please try again.");
        }
      } finally {
        setLoading(false); // Ocultar el spinner después de la respuesta
      }
    } else {
      openPopup("Invalid email address.");
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  
  return (
    <div className="noScrollBar signup-container">
      {loading ? (
        <Spinner /> // Mostrar el spinner mientras se carga
      ) : (
        <div className="glass-bg">
          <form onSubmit={onSubmit} className="signup-form">
            <span className="cormorant-font signup-form-title">
              Sign Up To
              <br /> Double Down
            </span>
            <div className="signup-form-group-div">
              <div className="signup-form-group">
                <label htmlFor="exampleInputEmail1" className="signup-form-label">
                  First Name
                </label>
                <input
                  type="text"
                  className="signup-form-control"
                  aria-describedby="nameHelp"
                  placeholder="Enter your first name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="signup-form-group">
                <label htmlFor="lastName" className="signup-form-label">
                  Last Name
                </label>
                <div style={{ position: "relative" }}>
                  <input
                    type="text"
                    className="signup-form-control"
                    placeholder="Your last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="signup-form-group">
                <label htmlFor="Email" className="signup-form-label">
                  Email
                </label>
                <div style={{ position: "relative" }}>
                  <input
                    type="email"
                    className="signup-form-control"
                    placeholder="Your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="signup-form-group">
                <label htmlFor="password" className="signup-form-label">
                  Password
                </label>
                <div style={{ position: "relative" }}>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="signup-form-control"
                    placeholder="Your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <img
                    onClick={handleTogglePasswordVisibility}
                    style={{
                      width: "30px",
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                      right: "10px",
                      cursor: "pointer",
                    }}
                    src={hidePassword}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <button className="signup-btn">Sign Up</button>
            <button className="signupGoogle-btn" style={{ display: 'none' }}>
              <img src={googleIcon} alt="google" />
              Sign in with Google
            </button>
            <div className="signup-haveaccount">
              <span style={{ color: "#9F9694" }}>Have an account?</span>
              <Link
                style={{
                  color: "#D1A757",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                to={"/sign-in"}
              >
                <span>Sign In</span>
              </Link>
            </div>
          </form>
        </div>
      )}

      {/* Popup para mostrar mensajes */}
      <Popup
        title="Alert"
        message={popupMessage}
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
      />
    </div>
  );
};

export default SignUp;
