import NavBar from "../home/NavBar";
import SideCart from "../cart/SideCart";
import useLayout from "./useLayout";


export default function Layout({ methods, children = null }) {
    const { cartItems } = methods;
  
  return (
    <>
        <NavBar cartItems={cartItems} />
        
        {children}
    </>
  )
}
