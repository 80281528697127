import axios from "axios";
import useUserStore from "../store/store";

// Obtener el token directamente del estado de Zustand
const { token } = useUserStore.getState().userData || {};



export const getData = async (url) => {



    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      return null; 
    }
  };

  export const getData3 = async (url) => {



    try {
      const response = await axios.get(url,{
        headers: {
          Authorization: `Token ${token}`,
        },});
      return response.data;
    } catch (error) {
      return null; 
    }
  };

  export const getData2 = async (url,data) => {
    try {
      const response = await axios.get(url,data);
      return response.data;
    } catch (error) {
      return null; 
    }
  };

  export const postData = async (url, data) => {
    try {
      const response = await axios.post(url, data);
      return response.data;
    } catch (error) {
      return null;
    }
  };

  export const postData3 = async (url, data) => {
    try {
      const response = await axios.post(url, data);
      return response.data;
    } catch (error) {
      // Verificar si hay una respuesta de error proporcionada por la API
      if (error.response && error.response.data) {
  
        // Retornar el mensaje de error proporcionado por la API
        return {
          success: false,
          errors: error.response.data.errors || [{ message: 'Unknown error occurred' }],
        };
      } else {
  
        // Retornar un mensaje de error genérico si no hay una respuesta de la API
        return {
          success: false,
          errors: [{ message: 'An unexpected error occurred. Please try again.' }],
        };
      }
    }
  };

  export const postData2 = async (url, data) => {
    try {
      // Obtener el token desde el localStorage
      const token = localStorage.getItem('credencialesUsuario')
        ? JSON.parse(localStorage.getItem('credencialesUsuario')).token
        : null;
  
      // Configurar los headers de la solicitud
      const headers = {
        Authorization: `Token ${token}`,
      };
  
      // Realizar la solicitud con axios
      const response = await axios.post(url, data, { headers });
  
      return response.data;
    } catch (error) {
      return null;
    }
  };

  export const postData4 = async (url, data) => {
    try {
      // Obtener el token desde el localStorage
      const token = localStorage.getItem('credencialesUsuario')
        ? JSON.parse(localStorage.getItem('credencialesUsuario')).token
        : null;
  
      // Configurar los headers de la solicitud
      const headers = {
        Authorization: `Token ${token}`,
      };
  
      // Realizar la solicitud con axios
      const response = await axios.post(url, data, { headers });
  
      return response.data;
    } catch (error) {
      // Verificar si hay una respuesta de error proporcionada por la API
      if (error.response && error.response.data) {
  
        // Retornar el mensaje de error proporcionado por la API
        return {
          success: false,
          errors: error.response.data.errors || [{ message: 'Unknown error occurred' }],
        };
      } else {
  
        // Retornar un mensaje de error genérico si no hay una respuesta de la API
        return {
          success: false,
          errors: [{ message: 'An unexpected error occurred. Please try again.' }],
        };
      }
    }
  };
  
export const deleteData = async (url, data) => {
    try {
      const response = await axios.put(url, data);
      return response.data;
    } catch (error) {
      return null;
    }
  };

  export const deleteData2 = async (url, data) => {
    try {
      const response = await axios.delete(url, data);
      return response.data;
    } catch (error) {
      return null;
    }
  };
  
export const updateData = async (url, data) => {
    try {
      const response = await axios.put(url, data);
      return response.data;
    } catch (error) {
      return null;
    }
  };

  export const updateData2 = async (url, data) => {
    try {
      const response = await axios.put(url, data);
      return response.data;
    } catch (error) {
      return null;
    }
  };
  
  export const patchData = async (url, data) => {
    try {
      // Obtener el token desde el localStorage
      const token = localStorage.getItem('credencialesUsuario')
        ? JSON.parse(localStorage.getItem('credencialesUsuario')).token
        : null;
  
      // Realizar la solicitud con axios
      const response = await axios.patch(
        url, // La URL del endpoint al que se hace el PATCH
        data, // Los datos a actualizar
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
  
      return response.data;
    } catch (error) {
      // Verificar si hay una respuesta de error proporcionada por la API
      if (error.response && error.response.data) {
  
        // Retornar el mensaje de error proporcionado por la API
        return {
          success: false,
          errors: error.response.data.errors || [{ message: 'Unknown error occurred' }],
        };
      } else {
  
        // Retornar un mensaje de error genérico si no hay una respuesta de la API
        return {
          success: false,
          errors: [{ message: 'An unexpected error occurred. Please try again.' }],
        };
      }
    }
  };