import React from 'react';

const Popup = ({ title, message, isOpen, onClose }) => {
  return (
    <div className={`popup-overlay ${isOpen ? 'active' : ''}`}>
      <div className="popup-content">
        <h2 className="popup-title">{title}</h2>
        <p className="popup-message">{message}</p>
        <button className="popup-close-btn" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default Popup;
