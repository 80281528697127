import { Route, Routes } from "react-router-dom";
import ContactUs from "../pages/public/ContactUs";
import SignUp from "../pages/public/SignUp";
import SignIn from "../pages/public/SignIn";
import ForgetPassword from "../pages/public/ForgetPassword";
import ResetPassword from "../pages/public/ResetPassword";
import SingleProduct from "../pages/public/SingleProduct";
import Products from "../pages/public/Products";
import NavBarWrapper from "../components/navbar/NavBarWrapper";
import Account from "../pages/private/Account";
import Cart from "../pages/public/Cart";
import Checkout from "../pages/public/Checkout";
import SuccessfulPayment from "../components/cart/SuccessfulPayment";
import HomeScreen from "../pages/HomeScreen";
import NavBarWrapperII from "../components/navbar/NavBarWrapperII";
import Layout from "../components/layout/Layout";
import useLayout from "../components/layout/useLayout";
import Request from "../pages/public/request";
import Request2 from "../pages/private/changeData";


const RouteIndex = () => {
  const props = useLayout();



  return (
    <Layout methods={props}>

    <Routes>
        <Route
        path="/" 
        element={<HomeScreen/>} 
        />
       <Route
        path="/quote"
        element={<ContactUs />}
      />
      <Route
        path="/sign-up"
        element={<SignUp />}
      />
      <Route
        path="/sign-in"
        element={<SignIn />}
      />
      <Route
        path="/forgot-password"
        element={<ForgetPassword />}
      />
      <Route
        path="/reset-password/:id/:token"
        element={<ResetPassword />}
      />
      <Route
        path="/single-product/:id"
        element={<SingleProduct />}
      />
      <Route
        path="/products"
        element={<Products />}
      />
      <Route
        path="/edit-user"
        element={<Request2 />}
      />
      <Route
        path="/account"
        element={<Account />}
      />
      <Route
        path="/cart"
        element={<Cart />}
      />
      <Route
        path="/cart/checkout"
        element={<Checkout />}
      />
      <Route
        path="/cart/success"
        element={<SuccessfulPayment />}
      />
      <Route
        path="/request/:id"
        element={<Request />}
      />
    </Routes>
    </Layout>

  );
};

export default RouteIndex;
