import React from "react";
import Index from "../../components/products/Index";
import useShowCart from "../../components/layout/useShowCart";


const Products = () => {
  useShowCart();
  return <Index />;
};

export default Products;
