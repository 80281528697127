import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Asegúrate de tener react-router-dom instalado para usar Link
import { APIS_Customers } from '../../helpers/ApiConsumo2';
import moment from 'moment';

const Account_Dashboard = ({colocarUsuario}) => {

   

    return (
        <div className="account-right-content">
            <table
                className="account-table"
                style={{ borderColor: "red", width: "100%", height: "100%" }}
            >
                <thead className="cormorant-font account-table-head">
                    <tr>
                        
                    <th>First name</th>
                        <th>Last name</th>
                        <th style={{ width: "35%" }}>Address</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Edit</th>
                        
                    </tr>
                </thead>
                <tbody className="gordita-font account-table-body">
          
                <tr>
                
                <td>{colocarUsuario?.first_name}</td>
                <td>{colocarUsuario?.last_name}</td>
                
                <td>{colocarUsuario?.address || 'N/A'}</td>
                <td>{colocarUsuario?.email}</td>
                <td>{colocarUsuario?.phone || 'N/A'}</td>
                <td><Link to={`/edit-user`} className='editar-usuario'>Edit</Link></td>
              </tr>
            
        
        </tbody>
            </table>
            
        </div>




    );

    
};

const DashboardContent = {
    title: "Account",
    content: <Account_Dashboard />,
};

export default Account_Dashboard;
export { DashboardContent };
