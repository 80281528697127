
export const leftImage = `${process.env.PUBLIC_URL}/assets/images/spades-card.svg`;
export const rightImage = `${process.env.PUBLIC_URL}/assets/images/hearts-card.svg`;
export const logo = `${process.env.PUBLIC_URL}/assets/images/logo.svg`;
export const logoT = `${process.env.PUBLIC_URL}/assets/images/logot.svg`;
export const navwrapperlogo = `${process.env.PUBLIC_URL}/assets/images/navwrapperlogo.svg`;
export const mobileLogo = `${process.env.PUBLIC_URL}/assets/images/mobileLogo.svg`;
export const mobileBG = `${process.env.PUBLIC_URL}/assets/images/mobileBG.svg`;
export const pl1 = `${process.env.PUBLIC_URL}/assets/images/pl1.svg`;
export const pl2 = `${process.env.PUBLIC_URL}/assets/images/pl2.svg`;
export const pl3 = `${process.env.PUBLIC_URL}/assets/images/pl3.svg`;
export const pr1 = `${process.env.PUBLIC_URL}/assets/images/pr1.svg`;
export const pr2 = `${process.env.PUBLIC_URL}/assets/images/pr2.png`;
export const pr3 = `${process.env.PUBLIC_URL}/assets/images/pr3.svg`;
export const welcomeTextImage = `${process.env.PUBLIC_URL}/assets/images/welcome-text.svg`;
export const bgImage = `${process.env.PUBLIC_URL}/assets/images/welcome-text-bg.svg`;
export const shadeShape = `${process.env.PUBLIC_URL}/assets/images/spades-shape.png`;
export const heartShape = `${process.env.PUBLIC_URL}/assets/images/hearts-shape.png`;
export const newHeart = `${process.env.PUBLIC_URL}/assets/images/newheart.svg`;
export const scroll = `${process.env.PUBLIC_URL}/assets/images/scroll.svg`;
export const hamburger = `${process.env.PUBLIC_URL}/assets/images/hamburger.svg`;
export const close = `${process.env.PUBLIC_URL}/assets/images/close.svg`;
export const cloverShape = `${process.env.PUBLIC_URL}/assets/images/clover-shape.svg`;
export const xIcon = `${process.env.PUBLIC_URL}/assets/images/X-icon.png`;
export const fbIcon = `${process.env.PUBLIC_URL}/assets/images/fb-icon.png`;
export const igIcon = `${process.env.PUBLIC_URL}/assets/images/ig-icon.png`;
export const slideBase = `${process.env.PUBLIC_URL}/assets/images/slide-base.svg`;
export const slide1 = `${process.env.PUBLIC_URL}/assets/images/showroom/slide_1-min.png`;
export const slide2 = `${process.env.PUBLIC_URL}/assets/images/showroom/slide_2-min.png`;
export const slide3 = `${process.env.PUBLIC_URL}/assets/images/showroom/slide_3-min.png`;
export const slide4 = `${process.env.PUBLIC_URL}/assets/images/showroom/slide_4-min.png`;
export const slide5 = `${process.env.PUBLIC_URL}/assets/images/showroom/slide_5-min.png`;
export const slide6 = `${process.env.PUBLIC_URL}/assets/images/showroom/slide_6-min.png`;
export const slide7 = `${process.env.PUBLIC_URL}/assets/images/showroom/slide_7-min.png`;
export const slide8 = `${process.env.PUBLIC_URL}/assets/images/showroom/slide_8-min.png`;
export const slide9 = `${process.env.PUBLIC_URL}/assets/images/showroom/slide_9-min.png`;
export const slide10 = `${process.env.PUBLIC_URL}/assets/images/showroom/slide_10-min.png`;
export const requestDice = `${process.env.PUBLIC_URL}/assets/images/request-dice.svg`;
export const tbg = `${process.env.PUBLIC_URL}/assets/images/tbg.svg`;
export const diamondCard = `${process.env.PUBLIC_URL}/assets/images/diamond-card.png`;
export const dice = `${process.env.PUBLIC_URL}/assets/images/dice.svg`;
export const coin = `${process.env.PUBLIC_URL}/assets/images/coin.svg`;
export const heartsButton = `${process.env.PUBLIC_URL}/assets/images/hearts-button.svg`;
export const bottomSpade = `${process.env.PUBLIC_URL}/assets/images/bottom-spade-card.svg`;
export const bottomHeart = `${process.env.PUBLIC_URL}/assets/images/bottom-heart-card.svg`;
export const lightBG = `${process.env.PUBLIC_URL}/assets/images/light-effect-bg.svg`;
export const blackJackBG = `${process.env.PUBLIC_URL}/assets/images/Blackjack-bg.svg`;
export const shoppingCart = `${process.env.PUBLIC_URL}/assets/images/shopping-cart.svg`;
export const leftArrow = `${process.env.PUBLIC_URL}/assets/images/left-arrow.svg`;
export const rightArrow = `${process.env.PUBLIC_URL}/assets/images/right-arrow.svg`;
export const sendIcon = `${process.env.PUBLIC_URL}/assets/images/send-icon.svg`;
export const leftContactbg1 = `${process.env.PUBLIC_URL}/assets/images/roulette.svg`;
export const leftContactbg2 = `${process.env.PUBLIC_URL}/assets/images/leftContactbg2.svg`;
export const rightContactbg = `${process.env.PUBLIC_URL}/assets/images/rightContactbg.svg`;
export const signupBg = `${process.env.PUBLIC_URL}/assets/images/signup-bg.svg`;
export const googleIcon = `${process.env.PUBLIC_URL}/assets/images/icons_google.svg`;
export const infoIcon = `${process.env.PUBLIC_URL}/assets/images/info.svg`;
export const warningIcon = `${process.env.PUBLIC_URL}/assets/images/warning.svg`;
export const spLightEffect = `${process.env.PUBLIC_URL}/assets/images/spLightEffect.svg`;
export const addtocarticon = `${process.env.PUBLIC_URL}/assets/images/addtocarticon.svg`;
export const plus = `${process.env.PUBLIC_URL}/assets/images/plus.svg`;
export const less = `${process.env.PUBLIC_URL}/assets/images/less.svg`;
export const deleteIcon = `${process.env.PUBLIC_URL}/assets/images/delete.svg`;
export const accountImg = `${process.env.PUBLIC_URL}/assets/images/accountimg.svg`;
export const dashboardIcon = `${process.env.PUBLIC_URL}/assets/images/dashboard 1.svg`;
export const dashboard2Icon = `${process.env.PUBLIC_URL}/assets/images/dashboard 2.svg`;
export const chevronRight = `${process.env.PUBLIC_URL}/assets/images/chevron-right.svg`;
export const orderIcon = `${process.env.PUBLIC_URL}/assets/images/checkout 1.svg`;
export const cardIcon = `${process.env.PUBLIC_URL}/assets/images/credit-card (1) 1.svg`;
export const settingsIcon = `${process.env.PUBLIC_URL}/assets/images/settings 1.svg`;
export const addressIcon = `${process.env.PUBLIC_URL}/assets/images/pin 1.svg`;
export const backArrow = `${process.env.PUBLIC_URL}/assets/images/backArrow.svg`;
export const success = `${process.env.PUBLIC_URL}/assets/images/confirmation 1.svg`;
export const hidePassword = `${process.env.PUBLIC_URL}/assets/images/hide-password.svg`;
export const cardInputIcon = `${process.env.PUBLIC_URL}/assets/images/card-input-icon.svg`;
export const rouletteIcon = `${process.env.PUBLIC_URL}/assets/images/roulette-icon.svg`;
export const contactBg = `${process.env.PUBLIC_URL}/assets/images/contact-bg.svg`;
export const cartBackArrow = `${process.env.PUBLIC_URL}/assets/images/cartBackArrow.svg`;
