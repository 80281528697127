import axios from "axios";
import {enviromentlocal } from "../enviroment/enviroment";
import { deleteData, getData, getData2, getData3, patchData, postData, postData3, updateData } from "./apiRequest";
import { useEffect } from "react";
import useUserStore from "../store/store";


export const APIS_Customers = async (method,id,body) => {



    try {
        let response; // Variable para almacenar la respuesta de axios
        let url=enviromentlocal
      switch (method) {
        case 'getAll':
          response = await getData3(url+'customers/my');

          break;
        case 'forgot':
            response = await postData3(url +'customers/forgot-password/', body);
          break;
        case 'login':
          response = await postData(url +'customers/login/', body);
          break;
        case 'register':
          response = await postData3(url +'customers/register/', body);
          break;
          case 'reset':
          response = await postData(url +'customers/reset-password/', body);
          break;
          case 'update':
          response = await patchData(url +'customers/update/', body);
          break;
          case 'contact':
          response = await postData3(url +'quote/', body);
          break;
        default:
          return null;
      }
  
      return response; // Retorna los datos obtenidos de la API
    } catch (error) {
      return null; // Retorna null o maneja el error como prefieras
    }
  };