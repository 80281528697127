import React from "react";
import {
  rightImage,
  leftImage,
  logoT,
  success,
} from "../../common/assets/images";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useShowCart from "../../components/layout/useShowCart";


const SuccessfulPayment = () => {
  useShowCart();
  const navigate = useNavigate();
  const handlePay = () => {
    window.location.replace("/");
  };
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          width: "90%",
          height: "100vh",
        }}
      >
        <span className="success-cart-heading cormorant-font gradient-text">
          thank you for
          <br />
          your rental
        </span>
        <img src={success} alt="" className="success-img" />
        <span className="gordita-font success-text">
          Your rental has been placed.
        </span>
        <button onClick={handlePay} className="checkout-mobile-bottom">
          Keep Exploring
        </button>
      </div>
    </div>
  );
};
export default SuccessfulPayment;
