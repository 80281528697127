import { useGSAP } from '@gsap/react';
import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

export default function useShowCart(smootherInstance) {
    const showCart = useRef(null);
    
    useGSAP(() => {
      showCart.current = gsap.timeline({
        paused: true,
      })
      .to('.side-cart', {
        xPercent: () => -100
      })
    }, []);
  
  
    const openCart = () => {
      showCart.current.tweenTo(showCart.current.totalDuration());
    }
    const closeCart = () => {
      showCart.current.tweenTo(0)
    }

    useEffect(() => {


      const cartClosers = gsap.utils.toArray('.cart-closer');
        cartClosers.forEach(closer => {
          closer.addEventListener('click', () => {
            closeCart();
            if (!smootherInstance) return;
            smootherInstance.current.paused(false);
          })
        })


        const cartOpeners = gsap.utils.toArray('.cart-opener');
        cartOpeners.forEach(opener => {
            opener.addEventListener('click', () => {
            openCart();
            if (!smootherInstance) return;
            smootherInstance.current.paused(true);
          })
        })
    }, [])


  return null;
}
