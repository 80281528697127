import React, { useEffect, useState } from "react";
import { 
  slideBase, 
  infoIcon,
  spLightEffect,
} from "../../common/assets/images"; 
import ProductCards from "../../common/dummydata/ProductCards";
import { SinglePageData, ExploreData } from "../../common/dummydata/DummyData";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, showAlert } from "../../state/actions";
import useShowCart from "../../components/layout/useShowCart";
import { APIS_GamesBooks } from "../../helpers/ApiConsumo";
import useUserStore from "../../store/store";


const SingleProduct = () => {
  useShowCart();
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch(); 
  const showAlertFlag = useSelector((state) => state.showAlert);
  const { id } = useParams();

  const [Casino,setCasino] = useState([])
  const [Casino2,setCasino2] = useState([])
  const [resok,setResok] = useState(0)
  const newData=[]

  const [colocarUsuario, setColocarUsuario] = useState([])
  const [colocarUsuario2, setColocarUsuario2] = useState([])

  const getUsers = async () => {
    const usuarios = await APIS_GamesBooks('get',id);
    const usuarios2 = await APIS_GamesBooks('getAll');
    setResok(resok + 1)
    setColocarUsuario(usuarios)
    setColocarUsuario2(usuarios2)
}

useEffect(() => {
  getUsers();
}, [id])

useEffect(() => {
  getUsers();
}, [])

useEffect(() => {
  setCasino(colocarUsuario);
  setCasino2(colocarUsuario2);
}, [resok])



  useEffect(() => {
    if (showAlertFlag) {
      alert("This item is already in the cart!");
      // Dispatch an action to hide the alert after showing it
      dispatch(showAlert());
    }
  }, [showAlertFlag, dispatch]);
  const handleAddToCart = () => {
    dispatch(addToCart(Casino)); // Dispatch the addToCart action with the item
  };

  // Obtener los datos del usuario del estado de Zustand
  const userData = useUserStore((state) => state.userData);


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setVisible(true);
    }, 50);

    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <div className="noScrollBar single-product-all">
      <div
        className="single-product-container"
      >
        <div className="glass-overlay-bg"></div>
        <div className="single-product-top-containet">
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              zIndex: 2,
              alignItems: "center",
            }}
          >
            <img src={spLightEffect} className="light-effect" alt="" />
            <img
              className="floating-img single-product-img"
              src={Casino?.image_url}
              alt=""
            />
            <img className="single-product-img-base" src={slideBase} alt="" />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "start",
              zIndex: 2,
            }}
          >
            <div className="single-product-text-container">
              <div className="single-product-text-top-container">
              <span className="single-product-text-title cormorant-font">
                {Casino?.name}
              </span>
              </div>
              <span className="single-product-text-description gordita-font">
                {Casino?.description}
              </span>
              <hr className="single-product-text-hr" />
              { userData!==null ? <Link to={`/request/${id}`}
                className="single-product-text-btn cormorant-font"
                onClick={handleAddToCart}
              >
                Request Now
              </Link> : <Link to={`/quote`}
                className="single-product-text-btn cormorant-font"
                onClick={handleAddToCart}
              >
                Request Now
              </Link>  }
              <span className="gordita-font single-product-text-info">
                <img style={{ width: "18px" }} src={infoIcon} alt="" />
                Prices shown above include professional casino dealer for up to
                3 hours of gaming. Delivery and taxes not included. Rates vary
                on holidays and the month of December.
              </span>
            </div>
          </div>
        </div>
      </div>
      {visible && (
        <div  className="single-product-explore-container">
          <span className="cormorant-font single-product-explore">
            Keep exploring
          </span>
          <ProductCards SliderData={Casino2} />
        </div>
      )}
    </div>
  );
};

export default SingleProduct;
