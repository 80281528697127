import React, { useEffect, useState } from "react";
import ProductCards from "../../common/dummydata/ProductCards";
import { SpecialityGamesData } from "../../common/dummydata/DummyData";
import { APIS_GamesBooks } from "../../helpers/ApiConsumo";

const SpecialityGames = () => {

  const [Casino, setCasino] = useState([]);
  const [resok, setResok] = useState(0);
  const [colocarUsuario, setColocarUsuario] = useState([]);

  const getUsers = async () => {
    const usuarios = await APIS_GamesBooks('getAll');
    // Filter to include only records where type is 'Casino'
    const casinoUsuarios = usuarios?.filter(user => user._type !== 'casino');
    setResok(resok + 1);
    setColocarUsuario(casinoUsuarios);
    console.log(casinoUsuarios);
  }

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    setCasino(colocarUsuario);
  }, [resok]);

  return (
    <div className="games-container">
      <ProductCards SliderData={Casino} />
    </div>
  );
};

export default SpecialityGames;
