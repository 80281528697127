import React, { useState, useRef, useEffect } from "react";
import SecondSection from "../components/home/SecondSection";
import useShowCart from '../components/layout/useShowCart';

import {
  leftImage,
  rightImage,
  logo,
  logoT,
  pl2,
  pl3,
  pr1,
  pr2,
  pr3,
  welcomeTextImage,
  shadeShape,
  heartShape,
  scroll,
  hamburger,
  slideBase,
  rouletteIcon,
  heartsButton,
  shoppingCart,
  leftArrow,
  rightArrow,
} from "../common/assets/images";
import image1 from  "../common/assets/slide1.svg"
import image2 from  "../common/assets/slide2.svg"
import image3 from  "../common/assets/slide3.svg"
import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {
  Pagination,
  Autoplay,
  EffectFade,
  Navigation,
  Keyboard,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import { SliderData } from "../common/dummydata/DummyData";
import { ScrollSmoother } from "../utils/ScrollSmoother";
import "../style/landingpage.css";
import { APIS_GamesBooks } from "../helpers/ApiConsumo";
import useUserStore from "../store/store";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

const MobileHomePage = () => {
  const userData = useUserStore((state) => state.userData);

  const navigate = useNavigate();
  const handleRent = (id) => {
    window.location.replace(`/single-product/${id}`);
  };

  const swiperRef = useRef(null);
  const leftImageRef = useRef(null);
  const rightImageRef = useRef(null);
  const logoImageRef = useRef(null);
  const logoImageRef2 = useRef(null);
  const pl2Ref = useRef(null);
  const pl3Ref = useRef(null);
  const pr1Ref = useRef(null);
  const pr2Ref = useRef(null);
  const pr3Ref = useRef(null);
  const rightImageRef2 = useRef(null);
  const leftImageRef2 = useRef(null);
  const welcomeTextRef = useRef(null);
  const bgRef = useRef(null);
  const videoBgRef = useRef(null);
  const bg2Ref = useRef(null);
  const bg3Ref = useRef(null);
  const bg4Ref = useRef(null);
  const shadeShapeRef = useRef(null);
  const heartShapeRef = useRef(null);
  const scrollRef = useRef(null);
  const slideBaseRef = useRef(null);
  const slide1Ref = useRef(null);
  const hamburgerRef = useRef(null);
  const shoppingCartRef = useRef(null);
  const signInButtonRef = useRef(null);
  // const secondSectionRef = useRef(null);

  const [Casino, setCasino] = useState([])
  const [resok, setResok] = useState(0)

  const [colocarUsuario, setColocarUsuario] = useState([])
  const [colocarUsuario2, setColocarUsuario2] = useState([])

  const getUsers = async () => {
    const usuarios = await APIS_GamesBooks('getAll');
    setResok(resok + 1)
    setColocarUsuario(usuarios)
  }

  useEffect(() => {
    getUsers();
  }, [])

  useEffect(() => {
    setCasino(colocarUsuario);
  }, [resok])


  useGSAP(() => {
    // ScrollTrigger.normalizeScroll(true);


    const handleClickNext = () => {
      const prevButton = document.querySelector(".custom-swiper-button-next");
      if (prevButton) {
        prevButton.click(); // Trigger click event
      }
    };
    const handleClickPrev = () => {
      const prevButton = document.querySelector(".custom-swiper-button-prev");
      if (prevButton) {
        prevButton.click(); // Trigger click event
      }
    };

    const leftImage = leftImageRef.current;
    const rightImage = rightImageRef.current;
    const logoImage = logoImageRef.current;
    const logoImage2 = '.lp-logo';
    const pl2Image = pl2Ref.current;
    const pl3Image = pl3Ref.current;
    const pr1Image = pr1Ref.current;
    const pr2Image = pr2Ref.current;
    const pr3Image = pr3Ref.current;
    const rightImage2 = '.heart-logo-img';
    const leftImage2 = '.shade-logo-img';
    const welcomeText = welcomeTextRef.current;
    const bg = bgRef.current;
    const videoBg = videoBgRef.current;
    const bg2 = bg2Ref.current;
    const bg3 = bg3Ref.current;
    const bg4 = bg4Ref.current;
    const shadeShape = shadeShapeRef.current;
    const heartShape = heartShapeRef.current;
    const scroll = scrollRef.current;
    const slideBase = slideBaseRef.current;
    const slide1 = slide1Ref.current;
    const hamburger = '.hamburger';
    const shoppingCart = '.nav-cart-btn';
    const signInButton = '.nav-sign-up';
    // const secondSection = secondSectionRef.current;

    gsap.set(hamburger, { opacity: 0, scale: 0.7 });
    gsap.set(".nav-item-container", { opacity: 0 });
    // gsap.set(signInButton, { opacity: 0 });
    gsap.set(shoppingCart, { opacity: 0 });
    // gsap.from(shoppingCart, { opacity: 0, scale: 0.4 });
    gsap.set(leftImage, { xPercent: "-100", y: 0, rotationY: 0 });
    gsap.from(rightImage, { opacity: 0, rotationY: 0 });
    gsap.set(logoImage, { opacity: 0, y: 60 });
    gsap.set(logoImage2, { opacity: 0 });
    gsap.set(pl2Image, { zIndex: 1, scale: 0.5 });
    gsap.set(pl3Image, { zIndex: 1, scale: 0.5 });
    gsap.set(pr1Image, { zIndex: 1, scale: 0.5 });
    gsap.set(pr2Image, { zIndex: 1, scale: 0.5 });
    gsap.set(pr3Image, { zIndex: 1, scale: 0.5 });
    gsap.from(pl2Image, { opacity: 0 });
    gsap.from(pl3Image, { opacity: 0 });
    gsap.from(pr1Image, { opacity: 0 });
    gsap.from(pr2Image, { opacity: 0 });
    gsap.from(pr3Image, { opacity: 0 });
    // gsap.set(leftImage2, { x: 0, y: "-200%" });
    // gsap.set(rightImage2, { x: 10, y: "-200%" });
    gsap.set(welcomeText, { opacity: 0 });
    gsap.set(bg, { opacity: 0 });
    gsap.set(videoBg, { opacity: 0 });
    gsap.from(bg2, { opacity: 0 });
    // gsap.set(".mySwiper", { opacity: 0, zIndex: 20 });
    gsap.set(bg3, { opacity: 0, x: 0, y: 0 });
    gsap.from(bg4, { opacity: 0 });
    gsap.set(shadeShape, { opacity: 0, filter: "blur(1px)", scale: 0.4 });
    gsap.set(heartShape, { opacity: 0, filter: "blur(1px)", scale: 0.4 });
    gsap.set(scroll, { opacity: 0, filter: "blur(1px)", scale: 0.4 });
    gsap.set(slideBase, { filter: "blur(10px)", opacity: 0, scale: 0.4 });
    // gsap.set(secondSection, { opacity: 0 });

    var shoppingCartTL = gsap.timeline({
      onComplete: function () {
        ScrollTrigger.create({
          trigger: ".large-container",
          start: "top+=10 top",
          end: "top+=80 top",
          scrub: true,
          animation: gsap
            .timeline()
            .to(shoppingCart, {
              opacity: 1,
            }),
        });
      },
    });
    // SHOPPING CART ICON



    // SHOPPING CART ICON
    var navItemContainerTL = gsap.timeline({
      onComplete: function () {
        ScrollTrigger.create({
          trigger: ".cont",
          start: "top ",
          // end: "bottom",
          scrub: true,
          // pin: ".nav-item-container",
          // pinSpacer: false,
          // pinSpacing: false,
          animation: gsap
            .timeline()
            .to(".nav-item-container", {
              opacity: 1,
              // backgroundColor: "rgba(0, 0, 0, 0.64)",
              // zIndex: 1,
              ease: "power2",
              duration: 0.001,
            })
            .to(".nav-item-container", {
              opacity: 1,
            }),
          toggleActions: "play none none none",
        });
      },
    });
    navItemContainerTL.to(".nav-item-container", {
      opacity: 1,
    });

    // HAMBURGER
    gsap.to(hamburger, {
      delay: 5,
      opacity: 1,
      duration: 2,
      scale: 1,
    });

    var logoTL = gsap.timeline();

    // Logo Animation
    logoTL.to(logoImage, {
      opacity: 1,
      delay: 1.5,
      duration: 3,
      ease: "none",
      scale: 0.8,
      y: 40,
      onComplete: () => {
        logoTL.to(logoImage, {
          scale: 0.5,
          opacity: 0,
          duration: 2,
          ease: "power2",
          onComplete: () => {
            logoTL.to(logoImage, {
              ease: "power1.inOut",
              delay: 1,
              opacity: 0,
              scrollTrigger: {
                trigger: ".container",
                start: "top 1px",
                end: "top 1px",
                scrub: true,
              },
            });
          },
        });
      },
    });
    // SCROLL
    gsap.to(scroll, {
      delay: 5,
      opacity: 1,
      duration: 2,
      scale: 0.5,
      onComplete: () => {
        // gsap.to(scroll, {
        //   opacity: 0,
        //   scale: 1.4,
        //   scrollTrigger: {
        //     trigger: ".container",
        //     start: "top 1px",
        //     end: "900",
        //     scrub: true,
        //     pin: scroll,
        //   },
        // });
      },
    });
    gsap.to(videoBg, {
      delay: 6,
      opacity: 1,
      duration: 2,
      onComplete: () => {
        // gsap.to(videoBg, {
        //   // opacity: 0,
        //   scrollTrigger: {
        //     trigger: ".cont",
        //     start: "top 1px",
        //     end: "900",
        //     scrub: true,
        //   },
        // });
      },
    });

    // INITIAL CODE
    gsap.to(bg, {
      delay: 5,
      opacity: 1,
      duration: 2,
      onComplete: () => {
        // gsap.to(bg, {
        //   opacity: 0,
        //   scrollTrigger: {
        //     trigger: ".cont",
        //     start: "top 1px",
        //     end: "900",
        //     scrub: true,
        //     // pin: bg,
        //   },
        // });
      },
    });

    var heartShapeTL = gsap.timeline({
      onComplete: function () {
        ScrollTrigger.create({
          trigger: ".cont",
          start: "top 1",
          end: "9500",
          scrub: true,
          // pin: heartShape,
          animation: gsap
            .timeline()
            .to(heartShape, {
              opacity: 1,
              x: "18vw",
              y: "3vh",
              scale: 1,
              // zIndex: 1,
              // filter: "blur(3px)",
              rotate: 80,
              duration: 0.02,
            })
            .to(heartShape, { x: "18vw", y: "3vh" })
            .to(heartShape, {
              x: "18vw",
              y: "3vh",
              opacity: 0,
              duration: 0.1,
            }),
          toggleActions: "play none none none",
        });
      },
    });
    heartShapeTL.to(heartShape, {
      delay: 5,
      opacity: 1,
      duration: 2,
      x: "15vw",
      y: "4vh",
      filter: "blur(0px)",
      scale: 1,
      zIndex: 1,
    });

    var shadeShapeTL = gsap.timeline({
      onComplete: function () {
        ScrollTrigger.create({
          trigger: ".cont",
          start: "top 1",
          end: "bottom top",
          scrub: true,
          // pin: shadeShape,
          animation: gsap
            .timeline()
            .to(shadeShape, {
              opacity: 1,
              x: "-33vw",
              rotate: -120,
              ease: "power2",
              duration: 0.02,
              // filter: "blur(3px)",
            })
            .to(shadeShape, {
              x: "-33vw",
            })
            .to(shadeShape, {
              x: "-35vw",
              opacity: 0,
              duration: 0.1,
            }),
          toggleActions: "play none none none",
        });
      },
    });
    shadeShapeTL.to(shadeShape, {
      delay: 5,
      opacity: 1,
      duration: 2,
      x: "-5vw",
      y: "25vh",
      filter: "blur(0px)",
      zIndex: 1,
      scale: 1.5,
    });

    var leftImgTL = gsap.timeline({
      onComplete: function () {
        ScrollTrigger.create({
          trigger: ".cont",
          start: "top 1",
          end: "10600",
          scrub: true,
          // pin: leftImage,
          animation: gsap
            .timeline()
            .to(leftImage, {
              x: "-15vw", //170
              // y: "-42vh", //240
              scale: 1.7,
              rotationY: 160,
              // rotation: 190,
              // filter: "blur(1px)",
              ease: "power2",
              duration: 0.001,
              // zIndex: 20,
            })
            .to(leftImage, {
              rotation: 94,
              x: "-15vw",
              // y: "-44vh",
              scale: 1,
              // filter: "blur(2px)",
              duration: 0.02,
              // zIndex: 20,
            })
            .to(leftImage, {
              x: "-15vw",
              // y: "-44vh"
            })
            .to(leftImage, {
              x: "-15vw",
              // y: "-46vh",
              opacity: 1,
              duration: 0.029,
              scale: 0.5,
              // zIndex: 40,
              rotate: 70,
            })
            .to(leftImage, {
              x: "-15vw",
              // y: "-46vh",
              opacity: 0,
              duration: 0.061,
              // zIndex: 40,
            }),
          toggleActions: "play none none none",
        });
      },
    });
    // LEFT Animation
    leftImgTL
      .to(leftImage, {
        x: "20vw",
        y: "-12vh",
        autoAlpha: 1,
        duration: 2,
        rotationY: -200,
        rotate: -9,
        rotateZ: 39,
        ease: "none",
      })
      .to(leftImage, {
        x: "24vw",
        y: "-14vh",
        scale: 1.2,
        duration: 0.5,
        ease: "none",
        rotationZ: 40,
        rotate: 10,
      })
      .to(leftImage, {
        x: "49vw",
        y: "-37vh",
        scale: 1.5,
        duration: 2,
        ease: "power2",
        rotationY: 340,
        rotation: 340,
        zIndex: 3,
      })
      .to(leftImage, {
        x: "18vw",
        y: "-37vh",
        scale: 1.7,
        duration: 2,
        rotationY: 160,
        rotation: 190,
        filter: "blur(1px)",
        ease: "power2",
        zIndex: 3,
      });

    var rightImgTL = gsap.timeline({
      onComplete: function () {
        ScrollTrigger.create({
          trigger: ".cont",
          start: "top 1",
          end: "10700",
          scrub: true,
          // pin: rightImage,
          animation: gsap
            .timeline()
            .to(rightImage, {
              x: "-10vw", //120
              // y: "-11vh",
              scale: 1.7,
              rotationY: 160,
              // rotation: 20,
              duration: 0.001,
              // filter: "blur(1px)",
              ease: "power2",
              // zIndex: 20,
              opacity: 1,
            })
            .to(rightImage, {
              rotation: 210,
              x: "-1vw",
              // y: "7vh",
              scale: 1,
              // filter: "blur(1px)",
              duration: 0.02,
              // zIndex: 20,
              opacity: 1,
            })
            .to(rightImage, {
              opacity: 1,
              x: "-1vw",
              // y: "7vh"
            })
            .to(rightImage, {
              opacity: 0,
              zIndex: 20,
              x: 20,
              //   y: 70,
              duration: 0.061,
              // filter: "blur(3px)",
            }),
          toggleActions: "play none none none",
        });
      },
    });

    // Right Animation
    rightImgTL
      .to(rightImage, {
        x: "-20vw",
        y: "-11vh",
        autoAlpha: 1,
        duration: 2,
        rotationY: -200,
        rotate: -9,
        rotateZ: 39,
        ease: "none",
      })
      .to(rightImage, {
        x: "-24vw",
        y: "-15vh",
        scale: 1.2,
        duration: 0.5,
        ease: "none",
        rotationZ: 40,
        rotate: 10,
      })
      .to(rightImage, {
        x: "-47.5vw",
        y: "-38vh",
        scale: 1.5,
        duration: 2,
        ease: "power2",
        rotationY: 340,
        zIndex: 2,
        rotation: 354,
      })
      .to(rightImage, {
        x: "-10vw",
        y: "-11vh",
        scale: 1.7,
        duration: 2,
        rotationY: 160,
        rotation: -20,
        filter: "blur(1px)",
        ease: "power2",
      });

 
    // pl3Image Animation
    const pl3TL = gsap.timeline({
      onComplete: function () {
        ScrollTrigger.create({
          trigger: ".cont",
          start: "top 1",
          end: "10500", //11000
          scrub: true,
          // pin: pl3Image,
          animation: gsap
            .timeline()
            .to(pl3Image, {
              x: "-16vw",
              marginTop: "8vh",
              duration: 0.001,
              opacity: 1,
              ease: "power2",
            })
            .to(pl3Image, {
              x: "-16vw",
            })
            .to(pl3Image, { x: "-16vw", opacity: 1 })
            .to(pl3Image, { opacity: 0, duration: 0.001 }),
          toggleActions: "play none none none",
        });
      },
    });
    pl3TL
      .to(pl3Image, {
        opacity: 1,
        delay: 4,
        duration: 0.4,
        ease: "none",
      })
      .to(pl3Image, {
        x: "-15vw", //8
        y: "-33vh", //170
        scale: 0.31,
        duration: 2,
        rotationY: 160,
        rotation: 40,
        ease: "power2",
      });

    // pr1Image Animation
    const pr1TL = gsap.timeline({
      onComplete: function () {
        ScrollTrigger.create({
          trigger: ".cont",
          start: "top 1",
          end: "9800",
          scrub: true,
          // pin: pr1Image,
          animation: gsap
            .timeline()
            .to(pr1Image, {
              x: "-48vw",
              // y: 170,
              marginTop: "30vh",
              duration: 0.001,
              // filter: "blur(1px)",
              ease: "power2",
              opacity: 1,
              scale: 1,
            })
            .to(pr1Image, {
              x: "-48vw",
              // filter: "blur(2px)",
              // y: 340,
              duration: 0.1,
              opacity: 1,
            })
            .to(pr1Image, {
              x: "-48vw",
              // y: 340,
              opacity: 1,
            })
            .to(pr1Image, { opacity: 0, duration: 0.001 }),
          toggleActions: "play none none none",
        });
      },
    });
    pr1TL
      .to(pr1Image, {
        opacity: 1,
        scale: 1,
        delay: 4,
        duration: 0.4,
        ease: "none",
      })
      .to(pr1Image, {
        x: "-48vw",
        y: "20vh",
        scale: 1,
        duration: 2,
        rotationY: 180,
        rotation: 310,
        filter: "blur(1px)",
        ease: "power2",
      });

    // plr2Image Animation
    const pr2TL = gsap.timeline({
      onComplete: function () {
        ScrollTrigger.create({
          trigger: ".cont",
          start: "top 1",
          end: "11000",
          scrub: true,
          // pin: pr2Image,
          animation: gsap
            .timeline()
            .to(pr2Image, {
              x: "22vw",
              // y: "7vh",
              duration: 0.001,
              // filter: "blur(1px)",
              ease: "power2",
              opacity: 1,
              scale: 1,
            })
            .to(pr2Image, {
              x: "22vw",
            })
            .to(pr2Image, {
              x: "27vw",
              marginTop: "-10vh",
              opacity: 0.4,
              duration: 0.05,
              scale: 1,
            })
            .to(pr2Image, {
              x: "30vw",
              opacity: 0.2,
              scale: 1,
              duration: 0.09,
            })
            .to(pr2Image, { opacity: 0, duration: 0.001 }),
          toggleActions: "play none none none",
        });
      },
    });
    pr2TL
      .to(pr2Image, {
        opacity: 1,
        delay: 4,
        duration: 0.4,
        ease: "none",
      })
      .to(pr2Image, {
        x: "22vw",
        y: "7vh",
        scale: 1,
        duration: 2,
        rotationY: 180,
        rotation: 20,
        filter: "blur(1px)",
        ease: "power2",
      });
    // INITIAL CODE


    // slider-section
    ScrollTrigger.create({
      trigger: bg2,
      start: "top bottom",
      end: "top center",
      // markers: true,
      scrub: true,
      // pin: bg2,
      animation: gsap
        .timeline()
        // .to(bg2, {
        //   opacity: 1,
        //   duration: 0.01,
        // })
        .to(bg2, { opacity: 1 })
      // .to(bg2, { opacity: 0, duration: 0.01 }),
      // toggleActions: "play none none none",
    });

    // var swiperTL = gsap.timeline({
    //   onComplete: function () {
    //     ScrollTrigger.create({
    //       trigger: ".cont",
    //       start: "top",
    //       scrub: 0.001,
    //       animation: gsap
    //         .timeline()
    //         .to(".mySwiper", {
    //           opacity: 1,
    //           yPercent: 0,
    //           duration: 0.01,
    //         })
    //         .to(".mySwiper", { opacity: 1 })
    //     });
    //   },
    // });
    // SWIPER


    ScrollTrigger.create({
      trigger: ".cont",
      start: "top 1",
      end: "8900",
      scrub: true,
      // pin: bg4,
      animation: gsap
        .timeline()
        .to(bg4, {
          opacity: 1,
          duration: 0.01,
        })
        .to(bg4, { opacity: 1 })
        .to(bg4, { opacity: 0, duration: 0.01 }),
      toggleActions: "play none none none",
    });

    // CHECKS

    // pl2Image Animation
    const pl2TL = gsap.timeline({
      onComplete: function () {
        ScrollTrigger.create({
          trigger: ".cont",
          start: "top 1",
          end: "11000",
          scrub: true,
          // pin: pl2Image,
          animation: gsap
            .timeline()
            .to(pl2Image, {
              x: -480,
              y: -0,
              scale: 1,
              duration: 0.001,
              rotationY: 60,
              rotation: 40,
              // filter: "blur(1px)",
              ease: "power2",
              opacity: 1,
            })
            .to(pl2Image, {
              x: -545,
              // filter: "blur(2px)",
              y: -0,
              duration: 0.1,
              opacity: 1,
            })
            .to(pl2Image, { x: -545, y: -0, opacity: 1 })
            .to(pl2Image, { opacity: 0, duration: 0.001 }),
          toggleActions: "play none none none",
        });
      },
    });

    pl2TL
      .to(pl2Image, {
        opacity: 1,
        delay: 4,
        duration: 0.4,
        ease: "none",
      })
      .to(pl2Image, {
        x: -480,
        y: -0,
        scale: 1,
        duration: 2,
        rotationY: 60,
        rotation: 40,
        filter: "blur(1px)",
        ease: "power2",
      });

    // pr3Image Animation
    const pr3TL = gsap.timeline({
      onComplete: function () {
        ScrollTrigger.create({
          trigger: ".cont",
          start: "top 1",
          end: "11000",
          scrub: true,
          // pin: pr3Image,
          animation: gsap
            .timeline()
            .to(pr3Image, {
              x: 450,
              y: 120,
              duration: 0.001,
              // filter: "blur(1px)",
              ease: "power2",
              opacity: 1,
              scale: 1,
            })
            .to(pr3Image, {
              x: 450,
              y: 120,
              // filter: "blur(2px)",
              duration: 0.1,
              opacity: 1,
            })
            .to(pr3Image, { x: 450, y: 120, opacity: 0 })
            .to(pr3Image, { opacity: 0, duration: 0.001 }),
          toggleActions: "play none none none",
        });
      },
    });
    pr3TL
      .to(pr3Image, {
        opacity: 1,
        delay: 4,
        duration: 0.4,
        ease: "none",
      })
      .to(pr3Image, {
        x: 450,
        y: 120,
        scale: 1,
        duration: 2,
        rotationY: 180,
        rotation: 20,
        filter: "blur(1px)",
        ease: "power2",
      });

    // NEW LOGO
    gsap.to(logoImage2, {
      delay: 6.5,
      opacity: 1,
    });
    // Right2 Animation
    gsap.from(rightImage2, {
      yPercent: -200,
      delay: 4.5,
      duration: 2,
      rotateZ: 39,
      ease: "bounce.in",

    });
    // Left2 Animation
    gsap.from(leftImage2, {
      yPercent: -200,
      delay: 4,
      duration: 2,
      rotateZ: 30,
      ease: "bounce.in",

    });
    // Welcome Text Animation
    gsap.to(welcomeText, {
      opacity: 1,
      delay: 2,
      duration: 2,
      ease: "power1.inOut",
    });
  }, []);

  useShowCart();

  return (
    <div style={{ width: "100%" }}>

      <div style={{ height: "100vh" }} className="start-home large-container">

        {/* WELCOME CONTAINER */}
        <div
          ref={bgRef}
          style={{
            display: "flex",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            overflow: "hidden",
            margin: "auto",
            //   backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            zIndex: 1,
          }}
          className="hero-section"
        >
          <video
            ref={videoBgRef}
            autoPlay muted
            loop
            playsInline
            style={{
              display: "flex",
              position: "absolute",
              zIndex: 0,
              width: "100%",
              height: "100%",
              overflow: "hidden",
              objectFit: "cover",
              objectPosition: "center",
              filter: "brightness(70%)",
            }}
            className="hero-section"
          >
            <source
              src={`${process.env.PUBLIC_URL}/assets/videos/welcome-video.mp4`}
              type="video/mp4"
            />
            Video not supported
          </video>
          <img ref={scrollRef} src={scroll} alt="logo" className="scroll-img" />
          <img
            ref={welcomeTextRef}
            src={welcomeTextImage}
            alt="logo"
            className="welcome-text"
          />
        </div>
        {/* WELCOME 2 */}
        <div className="welcome-animation">
          <img
            ref={leftImageRef}
            src={leftImage}
            alt="Animated"
            className="shade-card-img"
          />
          <img
            ref={rightImageRef}
            src={rightImage}
            alt="Animated"
            className="heart-card-img"
          />
          <img
            ref={logoImageRef}
            src={logo}
            alt="logo"
            className="welcome-logo"
          />
          <img ref={pl2Ref} src={pl2} alt="pl2" className="pl2" />
          <img ref={pl3Ref} src={pl3} alt="pl3" className="pl3" />
          <img ref={pr1Ref} src={pr1} alt="pr1" className="pr1" />
          <img ref={pr2Ref} src={pr2} alt="pr2" className="pr2" />
          <img ref={pr3Ref} src={pr3} alt="pr3" className="pr3" />
          <img
            ref={heartShapeRef}
            src={heartShape}
            alt="logo"
            className="heart-shape"
          />
          <img
            ref={shadeShapeRef}
            src={shadeShape}
            alt="logo"
            className="shade-shape"
          />
        </div>
        {/* SWIPPER */}
        <div className="slider-section" ref={bg2Ref}>
          <Swiper
            pagination={{
              clickable: true,
              renderBullet: function (index, className) {
                return (
                  '<span class="' + className + '">' + (index + 1) + "</span>"
                );
              },
              el: ".custom-pagination",
            }}
            modules={[Pagination, EffectFade, Autoplay, Navigation, Keyboard]}
            className="mySwiper"
            effect="fade" // Set effect to fade
            fadeEffect={{ crossFade: true }} // Enable cross fade effect
            // autoplay={{
            //   delay: 10000,
            //   disableOnInteraction: false,
            // }}
            navigation={{
              prevEl: ".custom-swiper-button-prev",
              nextEl: ".custom-swiper-button-next",
            }}
            keyboard={{
              enabled: true,
            }}
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {Casino?.map((items, index) => (
              <SwiperSlide key={index}>
                <div className="slider-img-cont">
                  <img
                    id={index == 1 ? 'carts' : ''}
                    src={items.image_url}
                    alt="logo"
                    className={`floating-img slider-img `}
                    style={{ justifySelf: "flex-end", display: "flex" }}
                  />
                  <img
                    src={slideBase}
                    alt="logo"
                    className="swipper-base-mobile"
                  />
                </div>{" "}
                <div className="slider-writeup">
                  <span className="cormorant-font gradient-text swipper-title">
                    {items.name}
                  </span>
                  <span className="swipper-description gordita-font">
                    {items.description}
                  </span>
                  {userData === null && !userData ? <button
                    onClick={() => handleRent(items.id)}
                    className="cormorant-font swipper-btn"
                  >
                    VIEW NOW
                    <img
                      alt="heartsButton"
                      src={index === 0 ? rouletteIcon : heartsButton}
                      style={{ width: "30px" }}
                    />
                  </button> : <button
                    onClick={() => handleRent(items.id)}
                    className="cormorant-font swipper-btn"
                  >
                    RENT NOW
                    <img
                      alt="heartsButton"
                      src={index === 0 ? rouletteIcon : heartsButton}
                      style={{ width: "30px" }}
                    />
                  </button>}
                </div>
              </SwiperSlide>
            ))}
            <div className="custom-pagination-navigation-div">
              {/* Custom previous button */}
              <div className="custom-swiper-button-prev">
                <img src={leftArrow} alt="leftArrow" />
              </div>
              {/* Custom pagination container */}
              <div className="custom-pagination"></div>
              {/* Custom next button */}
              <div className="custom-swiper-button-next">
                <img src={rightArrow} alt="leftArrow" />
              </div>
            </div>
          </Swiper>
        </div>
      </div>
      <div className="mobileSS" style={{ opacity: 1 }}>
        <SecondSection />
      </div>
    </div>
  );
};

const DesktopHomePage = () => {
  const navigate = useNavigate();
  const handleRent = (id) => {
    window.location.replace(`/single-product/${id}`);
  };


  const userData = useUserStore((state) => state.userData);

  const swiperRef = useRef(null);
  const leftImageRef = useRef(null);
  const rightImageRef = useRef(null);
  const logoImageRef = useRef(null);
  const logoImageRef2 = useRef(null);
  const pl2Ref = useRef(null);
  const pl3Ref = useRef(null);
  const pr1Ref = useRef(null);
  const pr2Ref = useRef(null);
  const pr3Ref = useRef(null);
  const rightImageRef2 = useRef(null);
  const leftImageRef2 = useRef(null);
  const welcomeTextRef = useRef(null);
  const bgRef = useRef(null);
  const videoBgRef = useRef(null);
  const bg2Ref = useRef(null);
  const bg3Ref = useRef(null);
  const bg4Ref = useRef(null);
  const shadeShapeRef = useRef(null);
  const heartShapeRef = useRef(null);
  const scrollRef = useRef(null);
  const slideBaseRef = useRef(null);
  const slide1Ref = useRef(null);
  const hamburgerRef = useRef(null);
  const shoppingCartRef = useRef(null);
  const signInButtonRef = useRef(null);
  const secondSectionRef = useRef(null);
  const smootherInstance = useRef(null);

  const [Casino, setCasino] = useState([])
  const [Casino2, setCasino2] = useState([])
  const [resok, setResok] = useState(0)

  const [colocarUsuario, setColocarUsuario] = useState([])

  const [colocarUsuario2, setColocarUsuario2] = useState([])

  const getUsers = async () => {
    const usuarios = await APIS_GamesBooks('getAll');
    const juegos = [
      {
        name:'All-In Package',
        description:"8 Black Jack Tables, 2 Full Size Craps Tables,2 Texas Hold'em Tables,2 Roulette Tables",
        price:'$1,600',
        imagen:image1
      },
      {
        name:'High Roller Package',
        description:"2 Blackjack tables, 2 Texas Hold'em tables', 1 Short craps table,1 Roulette table",
        price:'$1,600',
        imagen:image2

      },
      {
        name:'Big Bet Package',
        description:"5 Blackjack tables, 2 Texas Hold'em tables,1 Full-size Craps table, 1 Roulette table",
        price:'$2,500',
        imagen:image3
      },
    ]
    setResok(resok + 1)
    setColocarUsuario(usuarios)
    setColocarUsuario2(juegos)
   
  }

  useEffect(() => {
    getUsers();
  }, [])

  useEffect(() => {
    setCasino(colocarUsuario);
    setCasino2(colocarUsuario2);
  }, [resok])


  useGSAP(() => {
    smootherInstance.current = ScrollSmoother.create({
      content: "#smooth-content",
      smooth: 2,
      effects: true,
      normalizeScroll: true,
      smoothTouch: false,
    });
    smootherInstance.current.scrollTo(0);
    smootherInstance.current.paused(true);


    const handleClickNext = () => {
      const prevButton = document.querySelector(".custom-swiper-button-next");
      if (prevButton) {
        prevButton.click(); // Trigger click event
      }
    };
    const handleClickPrev = () => {
      const prevButton = document.querySelector(".custom-swiper-button-prev");
      if (prevButton) {
        prevButton.click(); // Trigger click event
      }
    };
    const swiper = swiperRef.current;
    const leftImage = leftImageRef.current;
    const rightImage = rightImageRef.current;
    const logoImage = logoImageRef.current;
    const logoImage2 = '.lp-logo';
    const pl2Image = pl2Ref.current;
    const pl3Image = pl3Ref.current;
    const pr1Image = pr1Ref.current;
    const pr2Image = pr2Ref.current;
    const pr3Image = pr3Ref.current;
    const rightImage2 = '.heart-logo-img';
    const leftImage2 = '.shade-logo-img';
    const welcomeText = welcomeTextRef.current;

    const videoBg = videoBgRef.current;
    const bg = bgRef.current;
    const bg2 = bg2Ref.current;
    const bg3 = bg3Ref.current;
    const bg4 = bg4Ref.current;
    const shadeShape = shadeShapeRef.current;
    const heartShape = heartShapeRef.current;
    const scroll = scrollRef.current;
    const slideBase = slideBaseRef.current;
    const slide1 = slide1Ref.current;
    const hamburger = '.hamburger';
    const shoppingCart = '.nav-cart-btn';
    const signInButton = '.nav-sign-up';
    const secondSection = secondSectionRef.current;

    // Set initial position of the Images
    gsap.set(leftImage, { xPercent: "-100", y: 0, rotationY: 0 });
    gsap.from(rightImage, { opacity: 0, rotationY: 0 });
    gsap.set(logoImage, { opacity: 0, y: 60 });
    gsap.set(logoImage2, { opacity: 0 });
    gsap.from(pl2Image, { opacity: 0, zIndex: 19 });
    gsap.from(pl3Image, { opacity: 0, zIndex: 19 });
    gsap.from(pr1Image, { opacity: 0, zIndex: 19 });
    gsap.from(pr2Image, { opacity: 0, zIndex: 19 });
    gsap.from(pr3Image, { opacity: 0, zIndex: 19 });
    // gsap.set(leftImage2, {  });
    // gsap.set(rightImage2, { x: 0, y: "-200%" });
    gsap.set(welcomeText, { opacity: 0 });
    gsap.set(bg, { opacity: 0 });
    gsap.from(bg2, { opacity: 0 });
    gsap.set(".mySwiper", { opacity: 0, zIndex: 20 });
    gsap.set(bg3, { opacity: 0, x: 0, y: 0 });
    gsap.from(bg4, { opacity: 0 });
    gsap.set(shadeShape, { opacity: 0, filter: "blur(1px)", scale: 0.4 });
    gsap.set(heartShape, { opacity: 0, filter: "blur(1px)", scale: 0.4 });
    gsap.set(".new-heart", {
      opacity: 0,
      filter: "blur(2px)",
      x: "-14.5vw",
      y: "36vh",
      rotate: "-80deg",
    });
    gsap.set(scroll, { opacity: 0, filter: "blur(1px)", scale: 0.4 });
    gsap.set(slideBase, { filter: "blur(10px)", opacity: 0, scale: 0.4 });
    gsap.set(secondSection, { opacity: 0 });
    gsap.set(slide1, { filter: "blur(10px)", opacity: 0, scale: 0.4 });
    gsap.set(hamburger, { opacity: 0, scale: 0.7 });
    gsap.set(signInButton, { opacity: 0 });
    gsap.set(shoppingCart, { opacity: 0 });
    let mm = gsap.matchMedia();

    mm.add(
      {
        isMobile: "(max-width: 900px)",
        isDesktop: "(max-width: 1600px)",
        isLargeDesktop: "(min-width: 1799px)",
      },
      (context) => {
        let { isMobile, isDesktop, isLargeDesktop } = context.conditions;

        ScrollTrigger.create({
          trigger: ".cont",
          start: `${window.innerHeight*2} top`,
          end: `${window.innerHeight*3.3} top`,
          scrub: true,
          pin: false,
          animation: gsap
            .timeline()


            .to(secondSection, {
              duration: 1,
              opacity: 1,
              zIndex: 1,
              // height: "100vh",
            })
            .to(secondSection, { opacity: 1 , duration: .2}),
          toggleActions: "play none none none",
        });

      

        gsap.to(bg, {
          delay: 1,
          opacity: 2,
          duration: 2,
          onComplete: () => {
            gsap.to(bg, {
              opacity: 0,
              scrollTrigger: {
                trigger: ".cont",
                start: "top 1px",
                end: `${window.innerHeight} top`,
                scrub: true,
                pin: bg,
              },
            });
            gsap.to(bg2, {
              opacity: 1,
              scrollTrigger: {
                trigger: ".cont",
                start: "top 1px",
                end: `${window.innerHeight} top`,
                scrub: true,
                pin: bg,
              },
            });
          },
        });
        
        ScrollTrigger.create({
          trigger: ".cont",
          start: "top 1",
          end: `${window.innerHeight*2} top`,
          scrub: true,
          pin: bg2,
          // markers: true,
          animation: gsap
            .timeline()
            .to(bg2, {
              opacity: 1,
            }),
          toggleActions: "play none none none",
        });
    
        var swiperTL = gsap.timeline({
          onComplete: function () {
            ScrollTrigger.create({
              trigger: ".cont",
              start: "top",
              end: "center",
              scrub: true,
              // pin: ".mySwiper",
              animation: gsap
                .timeline()
                .to(".mySwiper", {
                  opacity: 1,
                  duration: 0.01,
                }),
              toggleActions: "play none none none",
            });
          },
        });
    
    


        // pl3Image Animation
        const pl3TL = gsap.timeline({
          onComplete: function () {
            smootherInstance.current.paused(false)
            ScrollTrigger.create({
              trigger: ".cont",
              start: "top 1",
              end: "10500", //11000
              scrub: true,
              pin: false,
              animation: gsap
                .timeline()
               
              
            });
          },
        });
       

        // pr1Image Animation
        const pr1TL = gsap.timeline({
          onComplete: function () {
            ScrollTrigger.create({
              trigger: ".cont",
              start: "top 1",
              end: "9800",
              scrub: true,
              pin: pr1Image,
              animation: gsap
                .timeline()
                .to(pr1Image, {
                  x: isDesktop ? "-48vw" : "-48vw",
                  // y: 170,
                  marginTop: "30vh",
                  duration: 0.001,
                  // filter: "blur(1px)",
                  ease: "power2",
                  opacity: 1,
                  scale: isDesktop ? 3 : 3,
                })
                .to(pr1Image, {
                  x: isDesktop ? "-48vw" : "-48vw",
                  // filter: "blur(2px)",
                  // y: 340,
                  duration: 0.1,
                  opacity: 1,
                })
                .to(pr1Image, {
                  x: isDesktop ? "-48vw" : "-48vw",
                  // y: 340,
                  opacity: 1,
                })
                .to(pr1Image, { opacity: 0, duration: 0.001 }),
              toggleActions: "play none none none",
            });
          },
        });
        pr1TL
          .to(pr1Image, {
            opacity: 1,
            scale: 3,
            delay: 2,
            duration: 0.4,
            ease: "none",
          })
          .to(pr1Image, {
            x: isDesktop ? "-48vw" : "-48vw",
            y: "20vh",
            scale: 5,
            duration: 2,
            rotationY: 180,
            rotation: 310,
            // filter: "blur(1px)",
            ease: "power2",
          });

   
        var shoppingCartTL = gsap.timeline({
          onComplete: function () {
            ScrollTrigger.create({
              trigger: '.large-container',
              start: "top+=10 top",
              end: "top+=50 top",
              scrub: true,
              pinSpacing: false,
              animation: gsap
                .timeline()
                .to([shoppingCart, signInButton], {
                  opacity: 1,
                }),
            });
          },
        });
        // SHOPPING CART ICON
        // shoppingCartTL.to(shoppingCart, {
        //   opacity: 0,
        //   scale: 1,
        // });

        var NavCartTL = gsap.timeline({
          onComplete: function () {
            ScrollTrigger.create({
              trigger: shoppingCart,
              start: "top 10",
              end: isMobile ? "14500" : "15900",
              scrub: true,
              pin: ".nav-cart-badge",
              pinSpacing: false,
              animation: gsap
                .timeline()
                .to(".nav-cart-badge", {
                  opacity: 1,
                  xPercent: 0,
                  zIndex: 1,
                  ease: "power2",
                  duration: 0.001,
                })
                .to(".nav-cart-badge", {
                  opacity: 1,
                }),
            });
          },
        });
        // SHOPPING CART ICON
        NavCartTL.to(".nav-cart-badge", {
          opacity: 0,
          x: 0,
          scale: 1,
        });

        // HAMBURGER
        gsap.to(hamburger, {
          delay: 2,
          opacity: 1,
          duration: 2,
          scale: 1,
          zIndex: 20,
          onComplete: () => {
            gsap.to(hamburger, {
              x: 0,
              // y: 0,
              scrollTrigger: {
                trigger: ".container",
                start: "top 1px",
                end: isMobile ? "14500" : "15900",
                scrub: true,
                // pin: hamburger,
                pinSpacing: false,
              },
            });
          },
        });
        // // SIGN IN BUTTON
        // var signInButtonTL = gsap.timeline({
        //   onComplete: function () {
        //     ScrollTrigger.create({
        //       trigger: signInButton,
        //       start: "top 18",
        //       end: isMobile ? "14500" : "15900",
        //       scrub: true,
        //       // pin: signInButton,
        //       // pinSpacing: false,
        //       animation: gsap
        //         .timeline()
        //         .to(signInButton, {
        //           opacity: isMobile ? 0 : 1,
        //           zIndex: 1,
        //           ease: "power2",
        //           duration: 0.001,
        //         })
        //         .to(signInButton, { opacity: isMobile ? 0 : 1 }),
        //       toggleActions: "play none none none",
        //     });
        //   },
        // });

        // signInButtonTL.to(signInButton, {
        //   opacity: 0,
        //   // xPercent: 0,
        //   y: 0,
        //   scale: 1,
        // });

        // *************************************************
        var logoTL = gsap.timeline();

        // Logo Animation
        logoTL.to(logoImage, {
          opacity: 1,
          delay: 1.5,
          duration: 2,
          ease: "none",
          scale: isDesktop ? 1 : 2,
          y: 40,
          onComplete: () => {
            logoTL.to(logoImage, {
              // x: "-34.5399vw",
              // y: "-40.5vh",
              opacity: 0,
              scale: 0.5,
              duration: 2,
              ease: "power2",
              onComplete: () => {
                logoTL.to(logoImage, {
                  // x: "-34.5399vw",
                  // y: "-40.5vh",
                  ease: "power1.inOut",
                  zIndex: 1,
                  delay: 1,
                  opacity: 0,
                  scrollTrigger: {
                    trigger: ".container",
                    start: "top 1px",
                    end: "top 1px",
                    scrub: true,
                  },
                });
              },
            });
          },
        });
        // *************************************************

        // SCROLL
        gsap.to(scroll, {
          delay: 2,
          opacity: 1,
          duration: 2,
          scale: isLargeDesktop ? 0.8 : 0.5,
          onComplete: () => {
            gsap.to(scroll, {
              opacity: 0,
              scale: isLargeDesktop ? 1.6 : 1.4,
              scrollTrigger: {
                trigger: ".container",
                start: "top 1px",
                end: "900",
                scrub: true,
                pin: scroll,
              },
            });
          },
        });
      }
    );
    gsap.to(videoBg, {
      delay: 6,
      opacity: 2,
      duration: 2,
      onComplete: () => {
        gsap.to(videoBg, {
          opacity: 0,
          scrollTrigger: {
            trigger: ".cont",
            start: "top 1px",
            end: "900",
            scrub: true,
          },
        });
      },
    });

  
    // NEW LOGO
    gsap.to(logoImage2, {
      delay: 6.5,
      opacity: 1,
      onComplete: () => {
        gsap.to(logoImage2, {
          scrollTrigger: {
            trigger: ".container",
            start: "top 1px",
            scrub: true,
          },
        });
      },
    });
    // Right2 Animation
    gsap.from(rightImage2, {
      yPercent: -200,
      delay: 2.5,
      duration: 2,
      rotateZ: 30,
      ease: "bounce.in",
    });
    // Right2 Animation
    gsap.from(leftImage2, {
      yPercent: -200,
      delay: 2,
      duration: 2,
      // rotate: -12,
      rotateZ: 30,
      ease: "bounce.in",
    });
    // Welcome Text Animation
    gsap.to(welcomeText, {
      opacity: 1,
      delay: 3,
      duration: 2,
      ease: "power1.inOut",
      onComplete: () => {
        gsap.to(welcomeText, {
          opacity: 0,
          duration: 2,
          ease: "power1.inOut",
          zIndex: 1,
          scrollTrigger: {
            trigger: ".cont",
            start: "top 1px",
            end: "900",
            scrub: true,
          },
        });
      },
    });
  }, []);

  useShowCart(smootherInstance);



  return (
    <div id="smooth-content" style={{ width: "100%" }}>
      <div
        className='large-container'
        style={{ height: "300vh" }}
      >

        {/* WELCOME CONTAINER */}
        <div
          ref={bgRef}
          style={{
            display: "flex",
            position: "absolute",
            width: "100%",
            height: "100vh",
            overflow: "hidden",
            margin: "auto",
            //   backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="hero-section"
        >
          <video
            ref={videoBgRef}
            autoPlay muted
            loop
            playsInline
            style={{
              display: "flex",
              position: "absolute",
              width: "100%",
              height: "100%",
              overflow: "hidden",
              objectFit: "cover",
              objectPosition: "center",
              filter: "brightness(70%)",
            }}
            className="hero-section"
          >
            <source
              src={`${process.env.PUBLIC_URL}/assets/videos/welcome-video.mp4`}
              type="video/mp4"
            />
            Video not supported
          </video>
          {/* <img
    ref={slide1Ref}
    src={slide1}
    alt="logo"
    style={{
      position: "absolute",
      bottom: "37vh",
      left: "39vw",
      width: "300px",
    }}
    className="floating-img hero-section"
  />
  <img
    ref={slideBaseRef}
    src={slideBase}
    alt="logo"
    style={{
      position: "absolute",
      bottom: "10vh",
      left: "28vw",
    }}
    className="hero-section"
  /> */}
          <img ref={scrollRef} src={scroll} alt="logo" className="scroll-img" />
          <img
            ref={welcomeTextRef}
            src={welcomeTextImage}
            alt="logo"
            className="welcome-text"
          />
        </div>
        {/* WELCOME 2 */}
        <div className="welcome-animation">
          <img
            ref={leftImageRef}
            src={leftImage}
            alt="Animated"
            className="shade-card-img"
          />
          <div style={{ position: "absolute", top: "1vh", left: "1vw" }}>
            {/* <div>
              <div>
                <img
                  ref={leftImageRef2}
                  src={leftImage}
                  alt="Animated"
                  className="shade-logo-img"
                />{" "}
                <img
                  ref={rightImageRef2}
                  src={rightImage}
                  alt="Animated"
                  className="heart-logo-img"
                />
              </div>
              <img
                ref={logoImageRef2}
                src={logoT}
                alt="logo"
                className="lp-logo"
              />
            </div> */}
          </div>
          <img
            ref={rightImageRef}
            src={rightImage}
            alt="Animated"
            className="heart-card-img"
          />


          <img ref={pl2Ref} src={pl2} alt="pl2" className="pl2" />
          <img ref={pl3Ref} src={pl3} alt="pl3" className="pl3" />
          <img ref={pr1Ref} src={pr1} alt="pr1" className="pr1" />
          <img ref={pr2Ref} src={pr2} alt="pr2" className="pr2" />
          <img ref={pr3Ref} src={pr3} alt="pr3" className="pr3" />
          <img
            ref={heartShapeRef}
            src={heartShape}
            alt="logo"
            className="heart-shape"
          />
          <img src={heartShape} alt="logo" className="new-heart" />
          <img
            ref={shadeShapeRef}
            src={shadeShape}
            alt="logo"
            className="shade-shape"
          />
        </div>



        {/* SWIPPER */}
        <div className="slider-section" ref={bg2Ref}>
          <Swiper
            pagination={{
              clickable: true,
              renderBullet: function (index, className) {
                return (
                  '<span class="' + className + '">' + (index + 1) + "</span>"
                );
              },
              el: ".custom-pagination",
            }}
            modules={[Pagination, EffectFade, Autoplay, Navigation, Keyboard]}
            className="mySwiper"
            effect="fade" // Set effect to fade
            fadeEffect={{ crossFade: true }} // Enable cross fade effect
            // autoplay={{
            //   delay: 10000,
            //   disableOnInteraction: false,
            // }}
            navigation={{
              prevEl: ".custom-swiper-button-prev",
              nextEl: ".custom-swiper-button-next",
            }}
            keyboard={{
              enabled: true,
            }}
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {Casino?.map((items, index) => (
              <SwiperSlide key={index}>
                <div className="slider-writeup">
                  <span className="cormorant-font gradient-text swipper-title">
                    {items.name}
                  </span>
                  <span className="swipper-description gordita-font">
                    {items.description}
                  </span>
                  {userData === null && !userData ? <button
                    onClick={() => handleRent(items.id)}
                    className="cormorant-font swipper-btn"
                  >
                    VIEW NOW
                    <img
                      alt="heartsButton"
                      src={index === 0 ? rouletteIcon : heartsButton}
                      style={{ width: "30px" }}
                    />
                  </button> : <button
                    onClick={() => handleRent(items.id)}
                    className="cormorant-font swipper-btn"
                  >
                    RENT NOW
                    <img
                      alt="heartsButton"
                      src={index === 0 ? rouletteIcon : heartsButton}
                      style={{ width: "30px" }}
                    />
                  </button>}
                </div>
                <div className="slider-img-cont">
                  <img
                    id={index == 1 ? 'carts' : ''}
                    src={items.image_url}
                    alt="logo"
                    className={`floating-img slider-img `}
                    style={{ justifySelf: "flex-end", display: "flex" }}
                  />
                  <img
                    src={slideBase}
                    alt="logo"
                    className="swipper-base-mobile"
                  />
                </div>
              </SwiperSlide>
            ))}
            <img src={slideBase} alt="logo" className="swipper-base" />
            <div className="custom-pagination-navigation-div">
              {/* Custom previous button */}
              <div className="custom-swiper-button-prev">
                <img src={leftArrow} alt="leftArrow" />
              </div>
              {/* Custom pagination container */}
              <div className="custom-pagination"></div>
              {/* Custom next button */}
              <div className="custom-swiper-button-next">
                <img src={rightArrow} alt="leftArrow" />
              </div>
            </div>
          </Swiper>
        </div>
      </div>
      <div style={{ opacity: 0 }} ref={secondSectionRef}>
        <SecondSection />
      </div>
    </div>
  );
};

const HomeScreen = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 968);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 968);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {isMobile ? (
        <MobileHomePage
        />
      ) : (
        <DesktopHomePage
        />
      )}
    </>
  );
};

export default HomeScreen;
